import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';

function APISettingSMS(props) {
    const [remaining_credit, setRemaining_credit] = useState({});
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    useEffect(() => {
        api_read_smsconfig()
    }, []);

    async function api_read_smsconfig() {
        setmodal_load({ enable: true, message: "Loading SMS api data." })
        await sleep(500)

        const formData = new FormData()
        axios.post(props.app_hostname + "/api/account/setting/sms/readconfig", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    document.getElementById('sms-config-apiurl').value = response.data.apidata.api_url
                    document.getElementById('sms-config-apikey').value = response.data.apidata.api_key
                    document.getElementById('sms-config-apisecret').value = response.data.apidata.api_secret
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_save_smsconfig() {
        var formelement = document.getElementById('form-sms-config')
        const formData = new FormData()
        if (formelement.checkValidity() === true) {
            formelement.classList.remove("was-validated");
            setmodal_load({ enable: true, message: "Save SMS api data to database." })
            await sleep(500)

            formData.append('sms-config-apiurl', document.getElementById('sms-config-apiurl').value)
            formData.append('sms-config-apikey', document.getElementById('sms-config-apikey').value)
            formData.append('sms-config-apisecret', document.getElementById('sms-config-apisecret').value)

            axios.post(props.app_hostname + "/api/account/setting/sms/saveconfig", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        document.getElementById('sms-config-apiurl').value = response.data.apidata.api_url
                        document.getElementById('sms-config-apikey').value = response.data.apidata.api_key
                        document.getElementById('sms-config-apisecret').value = response.data.apidata.api_secret
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'Success',
                            message: "Your SMS configuration has been saved.",
                            enable: true
                        });
                    }
                    else {
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: err.message,
                        enable: true
                    });
                });
        } else {
            formelement.classList.add("was-validated");
        }
    }


    async function api_Send_smsmessage() {
        var formelement = document.getElementById('form-sms-test')
        const formData = new FormData()
        if (formelement.checkValidity() === true) {
            formelement.classList.remove("was-validated");
            setmodal_load({ enable: true, message: "Sending SMS to reciever." })
            await sleep(500)

            formData.append('sms-test-telno', document.getElementById('sms-test-telno').value)
            formData.append('sms-test-message', document.getElementById('sms-test-message').value)

            axios.post(props.app_hostname + "/api/account/tester/sms/sendmessage", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        document.getElementById('sms-test-result').value = JSON.stringify(response.data.data, undefined, 2)
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'Success',
                            message: "Your message has been sent to destination msisdn.",
                            enable: true
                        });
                    }
                    else {
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: err.message,
                        enable: true
                    });
                });
        } else {
            formelement.classList.add("was-validated");
        }
    }

    async function api_read_smscredit() {
        setmodal_load({ enable: true, message: "Reading SMS credit available fron API service." })
        await sleep(500)

        const formData = new FormData()
        axios.post(props.app_hostname + "/api/account/tester/sms/readcredit", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setRemaining_credit(response.data.credit.remaining_credit)
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'Success',
                        message: "Remaining credit has been update.",
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="fas fa-sms color-orange"></i> SMS</h4>
                            <p className="mb-3">Config and test your SMS API.</p>
                            <hr className="new-section-xs mb-2" />
                        </div>
                    </div>
                    <h5>SMS Configuration</h5>
                    <form id="form-sms-config" action="" method="POST" encType="multipart/form-data">
                        <div className="row mt-2 mb-1 mb-md-2">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="sms-config-apiurl">API URL</label>
                                    <input type="text" className="form-control" id="sms-config-apiurl" name="sms-config-apiurl" autoComplete='off' minLength={10} required />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 mb-1 mb-md-2">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="sms-config-apikey">API Key</label>
                                    <input type="text" className="form-control" id="sms-config-apikey" name="sms-config-apikey" autoComplete='off' minLength={10} required />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 mb-1 mb-md-2">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="sms-config-apisecret">API Secret</label>
                                    <input type="text" className="form-control" id="sms-config-apisecret" name="sms-config-apisecret" autoComplete='off' minLength={10} required />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="d-flex justify-content-start gap-3 mt-2">
                        <button className="btn btn-warning btn-sm" onClick={() => { api_save_smsconfig() }}><i className="fas fa-save"></i> Save Config</button>
                        <button className="btn btn-light btn-sm" onClick={() => { api_read_smsconfig() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                    </div>

                    <hr className="new-section-xs mb-2" />
                    <h5>SMS Sender Tool</h5>
                    <form id="form-sms-test" action="" method="POST" encType="multipart/form-data">
                        <div className="row mt-2 mb-0 mb-md-0">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="sms-test-telno">Tel No. (MSISDN)</label>
                                    <input type="text" className="form-control" id="sms-test-telno" name="sms-test-telno" autoComplete='off' minLength={10} required />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="sms-test-message">Message </label>
                                    <input type="text" className="form-control" id="sms-test-message" name="sms-test-message" autoComplete='off' minLength={1} required />
                                </div>
                            </div>
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="sms-test-result">API JSON Result </label>
                                    <textarea type="text" className="form-control" id="sms-test-result" name="sms-test-result" autoComplete='off' rows="5" readOnly />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="d-flex justify-content-start gap-3 mt-0">
                        <button className="btn btn-info btn-sm mt-0" onClick={() => { api_Send_smsmessage() }}><i className="fas fa-sms"></i> Send Message</button>
                    </div>

                    <hr className="new-section-xs mb-2" />
                    <h5>SMS Remaining Credit</h5>
                    <div className="row mt-2 mb-1 mb-md-2">
                        <div className="col-md-12 col-xl-6">
                            <div className="form-group">
                                <label htmlFor="sms-credit-standard">Standard Credit</label>
                                <input type="text" className="form-control" value={remaining_credit.standard} autoComplete='off' readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2 mb-1 mb-md-2">
                        <div className="col-md-12 col-xl-6">
                            <div className="form-group">
                                <label htmlFor="sms-credit-corporate">Corporate Credit</label>
                                <input type="text" className="form-control" value={remaining_credit.corporate} autoComplete='off' readOnly />
                            </div>
                        </div>

                        <div className="col-xl-12">
                            <div className="d-flex justify-content-start gap-3 mt-2">
                                <button className="btn btn-info btn-sm" onClick={() => { api_read_smscredit() }}><i className="fas fa-info-circle"></i> Read Credit</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

export default APISettingSMS