import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';

function APISettingEmail(props) {
    const [remaining_credit, setRemaining_credit] = useState({});
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    useEffect(() => {
        api_read_emailconfig()
    }, []);

    async function api_read_emailconfig() {
        setmodal_load({ enable: true, message: "Loading email api data." })
        await sleep(500)

        const formData = new FormData()
        axios.post(props.app_hostname + "/api/account/setting/email/readconfig", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    document.getElementById('smtp-server').value = response.data.apidata.smtp_server
                    document.getElementById('smtp-port').value = response.data.apidata.smtp_port
                    document.getElementById('smtp-mailsender').value = response.data.apidata.smtp_mailsender
                    document.getElementById('smtp-mailpasword').value = response.data.apidata.smtp_mailpasword
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_save_emailconfig() {
        var formelement = document.getElementById('form-email-config')
        const formData = new FormData()
        if (formelement.checkValidity() === true) {
            formelement.classList.remove("was-validated");
            setmodal_load({ enable: true, message: "Save email api data to database." })
            await sleep(500)

            formData.append('smtp-server', document.getElementById('smtp-server').value)
            formData.append('smtp-port', document.getElementById('smtp-port').value)
            formData.append('smtp-mailsender', document.getElementById('smtp-mailsender').value)
            formData.append('smtp-mailpasword', document.getElementById('smtp-mailpasword').value)

            axios.post(props.app_hostname + "/api/account/setting/email/saveconfig", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        document.getElementById('smtp-server').value = response.data.apidata.smtp_server
                        document.getElementById('smtp-port').value = response.data.apidata.smtp_port
                        document.getElementById('smtp-mailsender').value = response.data.apidata.smtp_mailsender
                        document.getElementById('smtp-mailpasword').value = response.data.apidata.smtp_mailpasword
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'Success',
                            message: "Your Email configuration has been saved.",
                            enable: true
                        });
                    }
                    else {
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: err.message,
                        enable: true
                    });
                });
        } else {
            formelement.classList.add("was-validated");
        }
    }


    async function api_Send_emailmessage() {
        var formelement = document.getElementById('form-email-test')
        const formData = new FormData()
        if (formelement.checkValidity() === true) {
            formelement.classList.remove("was-validated");
            setmodal_load({ enable: true, message: "Sending email content to reciever." })
            await sleep(500)

            formData.append('email-to', document.getElementById('email-to').value)
            formData.append('email-subject', document.getElementById('email-subject').value)
            formData.append('email-message', document.getElementById('email-message').value)

            axios.post(props.app_hostname + "/api/account/tester/email/sendmessage", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'Success',
                            message: "Your message has been sent to destination email.",
                            enable: true
                        });
                    }
                    else {
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: err.message,
                        enable: true
                    });
                });
        } else {
            formelement.classList.add("was-validated");
        }
    }

    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="far fa-envelope color-orange"></i> Email</h4>
                            <p className="mb-3">Config and test your Email API.</p>
                            <hr className="new-section-xs mb-2" />
                        </div>
                    </div>
                    <h5>Email Configuration</h5>
                    <form id="form-email-config" action="" method="POST" encType="multipart/form-data">
                        <div className="row mt-2 mb-1 mb-md-2">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="smtp-server">SMTP Server</label>
                                    <input type="text" className="form-control" id="smtp-server" name="smtp-server" autoComplete='off' minLength={6} required />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 mb-1 mb-md-2">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="smtp-port">SMTP Port</label>
                                    <input type="number" className="form-control" id="smtp-port" name="smtp-port" autoComplete='off' min={1} max={65535} required />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 mb-1 mb-md-2">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="smtp-mailsender">Email Sender From</label>
                                    <input type="email" className="form-control" id="smtp-mailsender" name="smtp-mailsender" autoComplete='off' required />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 mb-1 mb-md-2">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="smtp-mailpasword">Email Sender Password</label>
                                    <input type="text" className="form-control" id="smtp-mailpasword" name="smtp-mailpasword" autoComplete='off' minLength={3} required />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="d-flex justify-content-start gap-3 mt-2">
                        <button className="btn btn-warning btn-sm" onClick={() => { api_save_emailconfig() }}><i className="fas fa-save"></i> Save Config</button>
                        <button className="btn btn-light btn-sm" onClick={() => { api_read_emailconfig() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                    </div>

                    <hr className="new-section-xs mb-2" />
                    <h5>Email Sender Tool</h5>
                    <form id="form-email-test" action="" method="POST" encType="multipart/form-data">
                        <div className="row mt-2 mb-0 mb-md-0">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="email-to">Email To</label>
                                    <input type="text" className="form-control" id="email-to" name="email-to" autoComplete='off' minLength={10} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email-subject">Email Subject</label>
                                    <input type="text" className="form-control" id="email-subject" name="email-subject" autoComplete='off' minLength={3} required />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="email-message">Email Message</label>
                                    <textarea type="text" className="form-control" id="email-message" name="email-message" autoComplete='off' rows={5} minLength={1} required />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="d-flex justify-content-start gap-3 mt-2">
                        <button className="btn btn-info btn-sm mt-0" onClick={() => { api_Send_emailmessage() }}><i className="fas fa-sms"></i> Send Email</button>
                    </div>

                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

export default APISettingEmail