import React from 'react';
import { RotatingLines } from "react-loader-spinner";
import './ModalPopup.css';

class ModalLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <>
                {
                    this.props.option.enable === true ?
                        <>
                            <div className='meya-modal-loading'>
                                <div className="meya-modal-loading-body text-center" >
                                    <div className="meya-modal-loading-header">Please wait...</div>
                                    <div className="meya-modal-loading-content mb-5">{this.props.option.message}</div>
                                    <RotatingLines
                                        strokeColor="grey"
                                        strokeWidth="3"
                                        animationDuration="0.75"
                                        width="50"
                                        visible={true} />
                                </div>
                            </div>
                            {
                                this.props.backdrop !== undefined ?
                                    this.props.backdrop === true ?
                                        <div className='meya-backdrop-loading'></div>
                                        :
                                        null
                                    :
                                    <div className='meya-backdrop-loading'></div>
                            }
                        </>
                        :
                        null
                }
            </>
        )
    }
}


export default ModalLoad;