import React, { useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import PDFView from '../../components/pdf/PDFView';

function SubUsersApproved(props) {
    const [filtertext, setFiltertext] = useState("");
    const [user_filtered, setUser_Filtered] = useState([]);
    const [files, setFiles] = useState([]);
    const [users, setUsers] = useState([]);
    const [useritem, setUseritem] = useState({});
    const [fileselected, setFileselected] = useState("-");
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });
    const [modal_user, setModal_user] = useState(false);
    const [modal_file, setModal_file] = useState(false);

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    useEffect(() => {
        getUserList()
    }, []);

    useEffect(() => {
        if (filtertext !== "") {
            setUser_Filtered(users.filter((item, index) => (item.shop_id.toLowerCase().includes(filtertext.toLowerCase())
                || item.shop_name.toLowerCase().includes(filtertext.toLowerCase())
                || item.shop_email.toLowerCase().includes(filtertext.toLowerCase())
                || item.shop_province.toLowerCase().includes(filtertext.toLowerCase())
                || item.shop_telno.toLowerCase().includes(filtertext.toLowerCase()))))
        } else {
            setUser_Filtered(users)
        }
    }, [users, filtertext]);

    async function getUserList() {
        setmodal_load({ enable: true, message: "Loading shop account list." })
        await sleep(500)

        const formData = new FormData();
        formData.append('filter', 'approved')

        axios.post(props.app_hostname + "/api/account/shop/getlist", formData, {
            headers: {
                'Accept': 'application/json',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setUsers(response.data.users)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    function manageUsers(userdata) {
        setUseritem(userdata)
        setModal_user(true)
    }

    async function handleSubmit() {
        setmodal_load({ enable: true, message: "Save account to database." })
        await sleep(500)

        const formData = new FormData();
        formData.append('shop_id', useritem.shop_id)
        formData.append('status', useritem.status)
        formData.append('shopdocument', useritem.shopdocument)
        formData.append('bank_name', useritem.bank_name)
        formData.append('bank_acc_name', useritem.bank_acc_name)
        formData.append('bank_acc_id', useritem.bank_acc_id)
        formData.append('filter', 'approved')

        axios.post(props.app_hostname + "/api/account/shop/edit", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setUsers(response.data.users)
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'Success',
                        message: "Your user has been saved to database.",
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function readfile(userdata) {
        setmodal_load({ enable: true, message: "Read shop document from storage." })
        await sleep(500)

        setUseritem(userdata)

        const formData = new FormData();
        formData.append('shop_id', userdata.shop_id)

        axios.post(props.app_hostname + "/api/account/shop/getfiles", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setFiles(response.data.files)
                    setModal_file(true)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    function viewfile() {
        if (fileselected !== "") {
            if (fileselected.search(".png") > -1 || fileselected.search(".jpg") > -1 || fileselected.search(".jpeg") > -1) {
                return <img src={props.app_hostname + "/api/account/shop/file/shopdocument/" + useritem.shop_id + "/" + fileselected} alt='account files' />
            }
            if (fileselected.search(".pdf") > -1) {
                return <PDFView filepath={props.app_hostname + "/api/account/shop/file/shopdocument/" + useritem.shop_id + "/" + fileselected}></PDFView>
            }
        }
    }

    function select_filename(filename) {
        var stamp = moment(new Date()).format('DDMMYYYYHHmmss')
        var tmp = stamp + "_" + filename
        setFileselected(tmp)
    }

    async function downloadfile(id) {
        var stamp = id + "_" + moment(new Date()).format('DDMMYYYYHHmmss')
        if (files.length > 0) {
            const formData = new FormData();
            formData.append('shop_id', useritem.shop_id)
            if (files.length > 0) {
                formData.append('file1', files[0].filename)
            }
            if (files.length > 1) {
                formData.append('file2', files[1].filename)
            }
            if (files.length > 2) {
                formData.append('file3', files[2].filename)
            }
            if (files.length > 3) {
                formData.append('file4', files[3].filename)
            }

            axios.post(props.app_hostname + "/api/account/shop/downloadzipfile", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', stamp + '.zip');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: err.message,
                        enable: true
                    });
                });
        } else {
            setModal_popup({
                modaltype: 'modal-error',
                headers: 'Error',
                message: "No file were found.",
                enable: true
            });
        }
    }

    // Function to convert JSON to CSV string
    function convertJSONToCSV(jsonData, columnHeaders) {
        // Check if JSON data is empty
        if (jsonData.length === 0) {
            return '';
        }

        // Create headers string
        const headers = columnHeaders.join(',') + '\n';

        // Map JSON data to CSV rows
        const rows = jsonData
            .map((row) => {
                // Map each row to CSV format
                return columnHeaders.map((field) => row[field] || '').join(',');
            })
            .join('\n');

        // Combine headers and rows
        return headers + rows;
    }

    function downloadCSV() {
        var headers = [
            "shop_id",
            "shop_email",
            "shop_username",
            "shop_firstname",
            "shop_lastname",
            "shop_name",
            "shop_address",
            "shop_country",
            "shop_province",
            "shop_district",
            "shop_subdistrict",
            "shop_postcode",
            "userrole",
            "status",
            "lastlogin",
            "createdate",
            "approvedate",
            "shopdocument",
            "bank_name",
            "bank_acc_name",
            "bank_acc_id",
        ]
        const csvData = convertJSONToCSV(users, headers);

        // Check if CSV data is empty
        if (csvData === '') {
            alert('No data to export');
        } else {
            // Create CSV file and initiate download
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'meya_account_approved.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="fas fa-store-alt color-orange"></i> Approved Shop</h4>
                            <p className="mb-3">Manage all approved shop</p>
                            <hr className="new-section-xs mb-2" />
                            <div className="d-flex justify-content-start gap-3 mt-2">
                                <button className="btn btn-success btn-sm" onClick={() => { getUserList() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                                <button className="btn btn-info btn-sm" onClick={() => { downloadCSV() }}><i className="fas fa-file-csv"></i> Export CSV</button>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-1 mb-md-2">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
                            <div className="d-block mb-3">
                                <label htmlFor="status">Search Shop</label>
                                <div className="form-group position-ralative">
                                    <input type="text" className={"form-control" + (filtertext.length > 0 ? " filtered" : "")} placeholder='Shop ID, Shop Name, Email, Province, Tel No.' value={filtertext} onChange={(e) => { setFiltertext(e.target.value) }} />
                                    {
                                        filtertext.length > 0 ?
                                            <div className="form-control-append" onClick={() => { setFiltertext("") }}>
                                                <i className='fe-x'></i>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-1 mb-md-4">
                        <h5>Approved Shop List <span>({user_filtered.length} Accounts)</span></h5>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered" >
                                <thead>
                                    <tr className="admintable-header">
                                        <th className="id-fixwidth">#</th>
                                        <th>Shop ID</th>
                                        <th>Document</th>
                                        <th>Username / Email</th>
                                        <th>First Name / Last Name</th>
                                        <th>Shopname / Province</th>
                                        <th>Tel No.</th>
                                        <th>Bank Account</th>
                                        <th className="text-center action-fixwidth">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        user_filtered.map((user, index) => (
                                            <tr key={user.shop_id}>
                                                <td><i className="fas fa-store-alt rows-icon "></i> {index + 1}</td>
                                                <td className="font-bold my-0 py-0">{user.shop_id}</td>
                                                <td className={user.shopdocument === 'submited' ? "font-bold color-green" : null}>{user.shopdocument}</td>
                                                <td><p className="font-bold my-0 py-0">{user.shop_username}</p><p className='mb-0'>{user.shop_email}</p></td>
                                                <td>{user.shop_firstname} {user.shop_lastname}</td>
                                                <td>{user.shop_name} <p className='mb-0'>{user.shop_province}</p></td>
                                                <td><i className="fas fa-phone-alt rows-icon "></i> {user.shop_telno}</td>
                                                <td><p className="font-bold my-0 py-0">{user.bank_name}</p><p className='mb-0'>{user.bank_acc_id}</p></td>
                                                <td>
                                                    {
                                                        user.shopdocument === 'submited' ?
                                                            <Link className="btn btn-icon btn-sm btn-info btn-hover btn-bordered me-1" title="Document" href="#" onClick={() => { readfile(user) }}><i className="fas fa-file"></i></Link>
                                                            :
                                                            null
                                                    }
                                                    <Link className="btn btn-icon btn-sm btn-success btn-hover btn-bordered" title="Edit" href="#" onClick={() => { manageUsers(user) }}><i className="fas fa-pen"></i></Link>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="modal meya-overide " style={{ display: modal_user ? "block" : "none" }}>
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title"><i className="fas fa-store-alt color-orange"></i> Shop ID :  {useritem.shop_id}</h4>
                                    <i className="fe-x modal-close" onClick={() => {
                                        setModal_user(false)
                                    }}></i>
                                </div>
                                <div className="modal-body">
                                    <form id="form-users" action="" method="POST" encType="multipart/form-data">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="firstname">First name</label>
                                                    <input type="text" className="form-control" id="firstname" name="firstname" defaultValue={useritem.shop_firstname} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="lastname">Last name</label>
                                                    <input type="text" className="form-control" id="lastname" name="lastname" defaultValue={useritem.shop_lastname} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="firstname">Email</label>
                                                    <input type="text" className="form-control" id="email" name="email" defaultValue={useritem.shop_email} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="lastname">Username</label>
                                                    <input type="text" className="form-control" id="username" name="username" defaultValue={useritem.shop_username} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="firstname">Shop Name</label>
                                                    <input type="text" className="form-control" id="shopname" name="shopname" defaultValue={useritem.shop_name} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="lastname">Address</label>
                                                    <input type="text" className="form-control" id="address" name="address" defaultValue={useritem.shop_address} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="firstname">Sub District</label>
                                                    <input type="text" className="form-control" id="subdistrict" name="subdistrict" defaultValue={useritem.shop_subdistrict} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="lastname">District</label>
                                                    <input type="text" className="form-control" id="district" name="district" defaultValue={useritem.shop_district} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="firstname">Province</label>
                                                    <input type="text" className="form-control" id="province" name="province" defaultValue={useritem.shop_province} readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="lastname">Zip code</label>
                                                    <input type="text" className="form-control" id="postcode" name="postcode" defaultValue={useritem.shop_postcode} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="firstname">Tel.No</label>
                                                    <input type="text" className="form-control" id="telno" name="telno" defaultValue={useritem.shop_telno} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="status">Account Status</label>
                                                <select id="status" name="status" className="form-select" value={useritem.status} onChange={(e) => {
                                                    setUseritem(prevState => ({
                                                        ...prevState,
                                                        status: e.target.value
                                                    }))
                                                }}>
                                                    <option value="approved">Approved</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="suspended">Suspend</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="shopdocument">Document Status</label>
                                                <select id="shopdocument" name="shopdocument" className="form-select" value={useritem.shopdocument} onChange={(e) => {
                                                    setUseritem(prevState => ({
                                                        ...prevState,
                                                        shopdocument: e.target.value
                                                    }))
                                                }}>
                                                    <option value="submited">Submited</option>
                                                    <option value="pending">Pending</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="bank_name">Bank Name</label>
                                                <select id="bank_name" name="bank_name" className="form-select" value={useritem.bank_name} onChange={(e) => {
                                                    setUseritem(prevState => ({
                                                        ...prevState,
                                                        bank_name: e.target.value
                                                    }))
                                                }}>
                                                    <option value="-">-</option>
                                                    <option value="ธนาคารกรุงเทพ จำกัด (มหาชน)">ธนาคารกรุงเทพ จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารกรุงไทย จำกัด (มหาชน)">ธนาคารกรุงไทย จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)">ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารกสิกรไทย จำกัด (มหาชน)">ธนาคารกสิกรไทย จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารเกียรตินาคินภัทร จำกัด (มหาชน)">ธนาคารเกียรตินาคินภัทร จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารซิตี้แบงก์ เอ็น. เอ.">ธนาคารซิตี้แบงก์ เอ็น. เอ.</option>
                                                    <option value="ธนาคารซีไอเอ็มบี ไทย จำกัด (มหาชน)">ธนาคารซีไอเอ็มบี ไทย จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารทหารไทยธนชาต จำกัด (มหาชน)">ธนาคารทหารไทยธนชาต จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารทิสโก้ จำกัด (มหาชน)">ธนาคารทิสโก้ จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารไทยพาณิชย์ จำกัด (มหาชน)">ธนาคารไทยพาณิชย์ จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารยูโอบี จำกัด (มหาชน)">ธนาคารยูโอบี จำกัด (มหาชน)</option>
                                                    <option value="นาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) จำกัด (มหาชน)">ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) จำกัด (มหาชน)</option>
                                                    <option value="ธนาคารออมสิน">ธนาคารออมสิน</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="bank_acc_id">Bank Account No.</label>
                                                    <input type="text" className="form-control" id="bank_acc_id" name="bank_acc_id" defaultValue={useritem.bank_acc_id} onChange={(e) => {
                                                        setUseritem(prevState => ({
                                                            ...prevState,
                                                            bank_acc_name: e.target.value
                                                        }))
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="bank_acc_name">Bank Account Name</label>
                                                    <input type="text" className="form-control" id="bank_acc_name" name="bank_acc_name" defaultValue={useritem.bank_acc_name} onChange={(e) => {
                                                        setUseritem(prevState => ({
                                                            ...prevState,
                                                            bank_acc_id: e.target.value
                                                        }))
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-light  btn-sm" type="button" onClick={() => {
                                        setModal_user(false)
                                    }}><i className="fe-x"></i> Cancel</button>
                                    <button className="btn btn-warning btn-sm" onClick={() => { handleSubmit() }}><i className="fe-save"></i> Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal meya-overide " style={{ display: modal_file ? "block" : "none" }}>
                        <div className="modal-dialog modal-xl modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="d-block mb-0 px-0 py-0">
                                        <h4 className="modal-title"><i className="fas fa-file color-orange"></i> Shop ID :  {useritem.shop_id}</h4>
                                        <h5 className="modal-title mb-0 py-0">{useritem.shop_name} , {useritem.shop_province}</h5>
                                    </div>
                                    <i className="fe-x modal-close" onClick={() => {
                                        setModal_file(false);
                                        setFileselected("-");
                                        setFiles([])
                                    }}></i>
                                </div>
                                <div className="modal-body py-10">
                                    <div className="d-flex gap-1 mb-2">
                                        {
                                            files.map((file, index) => (
                                                <button key={file.filename} className={"btn btn-info btn-sm " + (fileselected === file.filename ? "active" : null)} onClick={() => { select_filename(file.filename) }}>{file.filename}</button>
                                            ))
                                        }
                                        <button className="btn btn-secondary btn-sm " onClick={() => { downloadfile(useritem.shop_id) }}><i className="mdi mdi-folder-zip-outline "></i> Download as Zip</button>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="d-block px-1 py-1 w-100">
                                            <div className="file-box">
                                                <div className="file-wrapper">
                                                    {viewfile()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-light  btn-sm" type="button" onClick={() => {
                                        setModal_file(false);
                                        setFileselected("-");
                                        setFiles([])
                                    }}><i className="fe-x"></i> Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modal_user || modal_file ?
                    <div className="meya-backdrop"></div>
                    :
                    null
            }
        </div>
    )
}

export default SubUsersApproved