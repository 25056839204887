import React, { useState, useEffect } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { pdfjs } from 'react-pdf';
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';


const PDFViewer = (props) => {
    //pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    pdfjs.GlobalWorkerOptions.workerSrc = `/libs/pdfjs-dist/legacy/build/pdf.worker.min.js`;
    const pdfURL = props.filepath;

    return (
        <Viewer
            fileUrl={pdfURL}
        />
    );
};

export default PDFViewer;