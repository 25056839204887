import React, { useEffect, useState } from 'react';
import {
    Link,
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import HelpDeskChat from './HelpDeskChat.js';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage.js';

function Refference(props) {
    const location = useLocation();
    const [submenu_root, setSubmenu_root] = useState('Chat');
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    useEffect(() => {
        if (location.pathname.search("/helpdesk/chat") > -1) {
            setSubmenu_root('Chat')
        }
    }, [location]);

    useEffect(() => {
        var bit1 = false
        var sticky1
        var header1 = null

        const onScroll = () => {
            if (bit1 === false) {
                header1 = document.getElementById("custom-bar");
                if (header1 != null) {
                    bit1 = true
                    sticky1 = header1.offsetTop;
                }
            }
            if (header1 != null) {
                if (window.scrollY > (sticky1 - 50)) {
                    header1.classList.add("sticky");
                } else {
                    header1.classList.remove("sticky");
                }
            }
        };
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    return (
        <section id="content" className="content">
            <div className="custom-bar" id="custom-bar">
                <div className="custom-bar-wrapper">
                    <div className="custom-bar-start">
                        <h5>Help Desk <i className="mdi mdi-chevron-double-right"></i> {submenu_root}</h5>

                    </div>
                    <div className="custom-bar-end">
                        <div className="timeboox">
                            <h5><i className="mdi mdi-clock-time-four-outline"></i> {props.data.systemtime}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content__boxed d-md-flex h-100">
                <div className="content__wrap order-2 flex-fill min-w-0 hi-100 border-left">
                    <Routes>
                        <Route path="/chat" element={
                            <HelpDeskChat
                                app_hostname={props.app_hostname}
                                permitdata={props.permitdata}
                                messages_chat_buffer={props.messages_chat_buffer}
                                isConnectionOpen_notify={props.isConnectionOpen_notify}
                                notify_ws={props.notify_ws}
                            >
                            </HelpDeskChat>
                        } />
                    </Routes>
                </div>
            </div>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                options={modal_popup}
            />
        </section>

    )
}
export default Refference