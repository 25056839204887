import React, { useState, useEffect, useRef } from 'react';
import {
    Link,
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import './ChatBox.css';

function HelpDeskChat(props) {
    const [messages_history, setMessages_history] = useState([]);
    const [isLoadHistoryCompleted, setisLoadHistoryCompleted] = useState(false);
    const [isConnectionOpen, setConnectionOpen] = useState(false);
    const [loadmore, setloadmore] = useState(true);
    const [scrollblock, setscrollblock] = useState(true);
    const [messageBody, setMessageBody] = useState("");

    const [room_list, setroom_list] = useState([])
    const [room_list_sorted, setroom_list_sorted] = useState([])
    const [room_selected, setroom_selected] = useState({
        roomcode: "",
        roomname: ""
    })

    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    useEffect(() => {
        api_load_chatrooms()
    }, []);

    useEffect(() => {
        if (room_selected.roomcode !== "") {
            api_chat_loadhistoryinitial()
        }
    }, [room_selected.roomcode]);

    useEffect(() => {
        if (isLoadHistoryCompleted) {
            if (props.messages_chat_buffer.id !== undefined) {
                if ((props.messages_chat_buffer.from === room_selected.roomcode && props.messages_chat_buffer.to === "admin")
                    || (props.messages_chat_buffer.from === "admin" && props.messages_chat_buffer.to === room_selected.roomcode)) {
                    if (messages_history.length > 0) {
                        const max = messages_history.reduce((prev, current) => {
                            return Number(prev.id) > Number(current.id) ? prev : current;
                        });
                        if (Number(props.messages_chat_buffer.id) > Number(max.id)) {
                            setMessages_history(oldArray => [...oldArray, props.messages_chat_buffer]);
                        }
                    } else {
                        setMessages_history(oldArray => [...oldArray, props.messages_chat_buffer]);
                    }
                    const nextroomlist = room_list.map(room => {
                        if (room.shop_id === props.messages_chat_buffer.from) {
                            return {
                                ...room,
                                lastid: props.messages_chat_buffer.id,
                            };
                        } else {
                            return room;
                        }
                    });
                    setroom_list(nextroomlist)
                    props.notify_ws.current.send(
                        JSON.stringify({
                            from: "admin",
                            to: room_selected.roomcode,
                            chat_type: "text",
                            topic: "chat-readed",
                            data: "",
                        })
                    );
                } else {
                    const nextroomlist = room_list.map(room => {
                        if (room.shop_id === props.messages_chat_buffer.from) {
                            return {
                                ...room,
                                unread: room.unread + 1,
                                lastid: props.messages_chat_buffer.id,
                            };
                        } else {
                            return room;
                        }
                    });
                    setroom_list(nextroomlist)
                }
            }
        } else {
            if (props.messages_chat_buffer.id !== undefined) {
                if (props.messages_chat_buffer.to === "admin") {
                    const nextroomlist = room_list.map(room => {
                        if (room.shop_id === props.messages_chat_buffer.from) {
                            return {
                                ...room,
                                unread: room.unread + 1,
                                lastid: props.messages_chat_buffer.id,
                            };
                        } else {
                            return room;
                        }
                    });
                    setroom_list(nextroomlist)
                }
            }
        }
        setscrollblock(false)
    }, [props.messages_chat_buffer]);

    useEffect(() => {
        setroom_list_sorted(
            room_list.sort((firstItem, secondItem) => (
                secondItem.lastid - firstItem.lastid
            )))
    }, [room_list]);

    useEffect(() => {
        setConnectionOpen(props.isConnectionOpen_notify)
    }, [props.isConnectionOpen_notify]);

    const scrollTarget = useRef();
    useEffect(() => {
        if (scrollTarget.current && scrollblock === false) {
            scrollTarget.current.lastElementChild.lastElementChild.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
        }
        setscrollblock(false)
    }, [messages_history.length]);

    const sendMessage = () => {
        if (messageBody) {
            if (room_selected.roomcode !== "") {
                props.notify_ws.current.send(
                    JSON.stringify({
                        from: "admin",
                        to: room_selected.roomcode,
                        chat_type: "text",
                        topic: "chat",
                        data: messageBody,
                    })
                );
                setMessageBody("");
            }
        }
    };

    async function api_load_chatrooms() {
        setmodal_load({ enable: true, message: "Loading chat rooms list." })
        await sleep(500)

        const formData = new FormData()
        axios.post("https://marketlive.meya.co.th/live/chat/admin/loadrooms", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setroom_list(response.data.rooms)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_chat_loadhistoryinitial() {
        setmodal_load({ enable: true, message: "Loading chat history" })
        await sleep(500)

        const formData = new FormData();
        formData.append('shop_id', room_selected.roomcode)
        formData.append('client_call', "admin")

        axios.post("https://marketlive.meya.co.th/live/chat/historyinitial", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                setisLoadHistoryCompleted(true)
                setMessages_history(response.data.chat)
                if (response.data.status === 'OK') {
                    const nextroomlist = room_list.map(room => {
                        if (room.shop_id === response.data.shop_id) {
                            return {
                                ...room,
                                unread: 0,
                            };
                        } else {
                            return room;
                        }
                    });
                    setroom_list(nextroomlist)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Sorry.',
                        message: 'Can not load chat history',
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setisLoadHistoryCompleted(true)
                setMessages_history([])
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    function chat_load_more(chat_id) {
        setscrollblock(true)
        setmodal_load({ enable: true, message: "กำลังโหลดประวัติการคุยก่อนหน้า" })

        const formData = new FormData();
        formData.append('shop_id', props.permitdata.shop_id)
        formData.append('chat_id', chat_id)

        axios.post("http://localhost:8090/chat/historyextended", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                setMessages_history(response.data.chat.concat(messages_history))
                if (response.data.status === 'OK') {
                    if (response.data.chat.length === 0) {
                        setloadmore(false)
                    }
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Sorry.',
                        message: 'Can not load chat history',
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }


    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="fab fa-rocketchat color-orange"></i> Chat</h4>
                            <p className="mb-3">Chat with your customers for best support.</p>
                            <hr className="new-section-xs mb-2" />
                            <div className="d-flex justify-content-start gap-3 py-2 border-bottom">
                                <button className="btn btn-success btn-sm" onClick={() => { api_load_chatrooms() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                            </div>
                        </div>
                    </div>

                    <div className="content__boxed d-md-flex px-0">
                        <aside className="w-md-250px content__wrap flex-shrink-0 pe-md-0 order-1 border-right px-0 pt-0 chat-room-list">
                            <h5 className="mt-0 mx-0 px-0">Shop List</h5>
                            <div className="list-group  gx-5 px-0">
                                {
                                    room_list_sorted.map((room, index) => (
                                        <div key={room.shop_id} className={"cursor-pointer room-list d-flex justify-content-between my-0 py-2 list-group-item list-group-item-action " + `${room_selected.roomcode === room.shop_id && "sub-active"}`}
                                            onClick={() => {
                                                setroom_selected(
                                                    {
                                                        roomcode: room.shop_id,
                                                        roomname: room.shop_name
                                                    }
                                                )
                                            }}>
                                            <div className='d-block'>
                                                <div className='my-0 py-0 room-shop-header'>{room.shop_name}</div>
                                                <p className='my-0 py-0'>{room.shop_province}</p>
                                            </div>
                                            {
                                                room.unread > 0 ?
                                                    <span className='badge'>{room.unread}</span>
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))
                                }
                            </div>

                        </aside>

                        <div className="content__wrap order-2 flex-fill min-w-0">
                            <div className={'meya-chat-box-message-header' + (room_selected.roomcode !== "" ? "" : " d-none")}>
                                <div className='chat-roomname'>Shop Name : {room_selected.roomname}</div>
                                <div className='meya-chat-box-status gap-2 mb-2'>
                                    <div><i className="fas fa-headset text-primary"></i> Connection State : </div>
                                    {isConnectionOpen ? <div className='text-success'>Connected</div> : <div className='text-danger'> Disconnected</div>}
                                </div>
                            </div>
                            <div className={'meya-chat-box' + (room_selected.roomcode !== "" ? "" : " d-none")}>
                                <div className='meya-chat-box-message-wrapper' ref={scrollTarget}>
                                    <div className="chat">
                                        <div className="chat-message">
                                            {
                                                loadmore === true && messages_history.length > 0 ?
                                                    <div className='chat-load-more'>
                                                        <div className='load-more-btn' onClick={() => { chat_load_more(messages_history[0].id) }}>
                                                            Load history more
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                messages_history.map((data, index0) => (
                                                    (data.from === "admin" && data.to === room_selected.roomcode) || (data.from === room_selected.roomcode && data.to === "admin") ?
                                                        <div className="message" key={"msgid-" + index0}>
                                                            {
                                                                data.from === "admin" ?
                                                                    <div className="response">
                                                                        <div className='d-block' style={{ float: "right" }}>
                                                                            <p className="text">{data.data}</p>
                                                                            <div className="response-time">
                                                                                {data.time}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : data.from === room_selected.roomcode ?
                                                                        <div className='d-block'>
                                                                            <p className="text">{data.data}</p>
                                                                            <div className="time">
                                                                                {data.time}
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='meya-chat-box-tool-wrapper'>
                                    <textarea type="text" className='writer-message' id="chat-message" placeholder='Type your message here' value={messageBody} onChange={(e) => setMessageBody(e.target.value)} />
                                    <div className='writer-btn' onClick={(e) => { sendMessage() }}>
                                        <i className="fe-send"></i>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

export default HelpDeskChat