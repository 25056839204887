import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';

function APITesterEmail(props) {
    const [factors, setFactors] = useState(
        {
            creditcard_charge_100: 0,
            delivery_charge_100: 0,
            service_margin_100: 0
        }
    )

    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    useEffect(() => {
        api_read_factor()
    }, []);

    async function api_read_factor() {
        setmodal_load({ enable: true, message: "Loading commercial factor." })
        await sleep(500)

        const formData = new FormData()
        axios.post(props.app_hostname + "/api/order/factor/read", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setFactors(
                        {
                            creditcard_charge_100: response.data.factor.creditcard_charge_100,
                            delivery_charge_100: response.data.factor.delivery_charge_100,
                            service_margin_100: response.data.factor.service_margin_100
                        })
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function save_factor() {
        var formelement = document.getElementById('form-factor')
        const formData = new FormData()
        if (formelement.checkValidity() === true) {
            setmodal_load({ enable: true, message: "Save commercial factor to database." })
            await sleep(500)

            formelement.classList.remove("was-validated");
            formData.append('creditcard_charge', factors.creditcard_charge_100)
            formData.append('delivery_charge', factors.delivery_charge_100)
            formData.append('service_margin', factors.service_margin_100)

            axios.post(props.app_hostname + "/api/order/factor/edit", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'Success',
                            message: "Your factor has been saved to database.",
                            enable: true
                        });
                    }
                    else {
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: err.message,
                        enable: true
                    });
                });
        } else {
            formelement.classList.add("was-validated");
        }
    }

    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="fas fa-calculator color-orange"></i> Factor</h4>
                            <p className="mb-3">Markup commercial margin.</p>
                            <hr className="new-section-xs mb-2" />
                            <div className="d-flex justify-content-start gap-3 mt-2">
                                <button className="btn btn-success btn-sm" onClick={() => { api_read_factor() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                            </div>
                        </div>
                    </div>
                    <h5 className='mt-4'>Commercial Factor</h5>
                    <form id="form-factor" action="" method="POST" encType="multipart/form-data">
                        <div className="row mt-2 mb-0 mb-md-0">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="creditcard_charge">Credit Charge</label>
                                    <input type="number" className="form-control" id="creditcard_charge" name="creditcard_charge" min={0.001} max={100} step={0.001} value={Number(factors.creditcard_charge_100).toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 3 })} required />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="delivery_charge">Delivery Fee</label>
                                    <input type="number" className="form-control" id="delivery_charge" name="delivery_charge" min={0.001} max={100} step={0.001} value={Number(factors.delivery_charge_100).toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 3 })} required />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="service_margin">Service Fee</label>
                                    <input type="number" className="form-control" id="service_margin" name="service_margin" min={0.001} max={100} step={0.001} value={Number(factors.service_margin_100).toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 3 })} required />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="d-flex justify-content-start gap-3 mt-2">
                        <button className="btn btn-warning btn-sm mt-0" onClick={() => { save_factor() }}><i className="fas fa-save"></i> Save</button>
                    </div>
                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

export default APITesterEmail