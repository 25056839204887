import React, { useEffect, useState } from 'react';
import {
    Link,
} from "react-router-dom";
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage.js';
import axios from 'axios';

function AccountOffice(props) {
    const [users, setUsers] = useState([]);
    const [useritem, setUseritem] = useState({});
    const [usermode, setUsermode] = useState('');
    const [sitemodeModal, setSitemodeModal] = useState('');
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });
    const [modal_user, setModal_user] = useState(false);

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        api_deleteUsers()
    }

    useEffect(() => {
        api_getUserList()
    }, []);

    useEffect(() => {
        var bit1 = false
        var sticky1
        var header1 = null

        const onScroll = () => {
            if (bit1 === false) {
                header1 = document.getElementById("custom-bar");
                if (header1 != null) {
                    bit1 = true
                    sticky1 = header1.offsetTop;
                }
            }

            if (header1 != null) {
                if (window.scrollY > (sticky1 - 50)) {
                    header1.classList.add("sticky");
                } else {
                    header1.classList.remove("sticky");
                }
            }
        };
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    async function api_getUserList() {
        setmodal_load({ enable: true, message: "Loading office account list." })
        await sleep(500)

        const formData = new FormData();
        axios.post(props.app_hostname + "/api/account/office/getlist", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setUsers(response.data.users)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    function manageUsers(mode, userdata) {
        if (mode !== 'delete') {
            var formelement = document.getElementById('form-users')
            formelement.classList.remove("was-validated");
            setUsermode(mode)
            if (mode === 'add') {
                setModal_user({
                    display: 'block',
                })
                setSitemodeModal('Add')
                document.getElementById("username").readOnly = false;
                document.getElementById('username').value = ""
                document.getElementById('password-office').value = ""
                document.getElementById('confirmpassword-office').value = ""
                document.getElementById('firstname').value = ""
                document.getElementById('lastname').value = ""
            }
            else if (mode === 'edit') {
                setModal_user({
                    display: 'block',
                })
                setSitemodeModal('Edit')
                setUseritem(userdata)
                document.getElementById("username").readOnly = true;
                document.getElementById('username').value = userdata.username
                document.getElementById('password-office').value = userdata.password
                document.getElementById('confirmpassword-office').value = userdata.password
                document.getElementById('firstname').value = userdata.firstname
                document.getElementById('lastname').value = userdata.lastname
            }
        }
        else {
            setUseritem(userdata)
            setUsermode(mode)
            setModal_popup({
                modaltype: 'modal-confirm',
                headers: 'Confirmation',
                message: "Please click on confirm button to continue this operation.",
                enable: true
            });
        }
    }

    async function api_handleSubmit() {
        var uname = document.getElementById('username').value
        if (usermode === 'add') {
            if (uname.toUpperCase() === 'ADMIN') {
                document.getElementById('username').setCustomValidity("Not Accept!");
            } else {
                document.getElementById('username').setCustomValidity("");
            }
        }

        var password = document.getElementById('password-office')
        var confirm_password = document.getElementById('confirmpassword-office')
        if (password.value === confirm_password.value) {
            confirm_password.setCustomValidity("");
        }
        else {
            confirm_password.setCustomValidity("Passwords Don't Match");
        }

        var formelement = document.getElementById('form-users')
        if (formelement.checkValidity() === false) {
            formelement.classList.add("was-validated");
        }
        else {
            setmodal_load({ enable: true, message: "Save users data to database." })
            await sleep(500)

            formelement.classList.remove("was-validated");
            const formData = new FormData(formelement);
            if (usermode === 'edit') {
                formData.append('id', useritem.id)
            }
            axios.post(props.app_hostname + "/api/account/office/" + usermode, formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        setUsers(response.data.users)
                        setModal_user(false)
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'Success',
                            message: "Your user has been saved to database.",
                            enable: true
                        });
                    }
                    else {
                        setModal_user(false)
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_user(false)
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: err.message,
                        enable: true
                    });
                });
        }
    }

    async function api_deleteUsers() {
        setmodal_load({ enable: true, message: "Deleting user data from database." })
        await sleep(500)

        var postdata = {
            id: useritem.id
        }
        axios.post(props.app_hostname + "/api/account/office/delete", postdata, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setUsers(response.data.users)
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'Success',
                        message: "Your user has been deleted from database.",
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <section id="content" className="content">
            <div className="custom-bar" id="custom-bar">
                <div className="custom-bar-wrapper">
                    <div className="custom-bar-start">
                        <h5>Office Account</h5>

                    </div>
                    <div className="custom-bar-end">
                        <div className="timeboox">
                            <h5><i className="mdi mdi-clock-time-four-outline"></i> {props.data.systemtime}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content__boxed d-md-flex h-100">
                <div className="content__wrap order-2 flex-fill min-w-0">
                    <div id="page-content">
                        <div className="panel">
                            <div className="panel-body">
                                <div className="row mb-2">
                                    <div className="col-xl-12">
                                        <h4 className="mb-1"><i className="fas fa-user-shield color-orange"></i> Office Account</h4>
                                        <p className="mb-3">Tool for manage office account</p>
                                        <hr className="new-section-xs mb-2" />
                                        <div className="d-flex justify-content-start gap-3 mt-2">
                                            <button className="btn btn-success btn-sm" onClick={() => { manageUsers('add', []) }}><i className="fas fa-plus"></i> Add New</button>
                                            <button className="btn btn-success btn-sm" onClick={() => { api_getUserList() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3 mb-1 mb-md-4">
                                    <h5>Office Account List <span>({users.length} Accounts)</span></h5>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered" >
                                            <thead>
                                                <tr className="admintable-header">
                                                    <th className="id-fixwidth">#</th>
                                                    <th>Username</th>
                                                    <th>Firstname</th>
                                                    <th>Lastname</th>
                                                    <th className="text-center action-fixwidth"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    users.map((user, index) => (
                                                        <tr key={user.id}>
                                                            <td><i className="fas fa-user-shield rows-icon"></i> {index + 1}</td>
                                                            <td className="font-bold my-0 py-0">{user.username}</td>
                                                            <td>{user.firstname}</td>
                                                            <td>{user.lastname}</td>
                                                            <td>
                                                                <Link className="btn btn-icon btn-sm btn-success btn-hover btn-bordered" title="Edit" href="#" onClick={() => { manageUsers('edit', user) }}><i className="fas fa-pen"></i></Link>
                                                                <Link className="btn btn-icon btn-sm btn-danger btn-hover btn-bordered mx-1" title="Delete" href="#" onClick={() => { manageUsers('delete', user) }}><i className="fas fa-eraser"></i></Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className="modal meya-overide " style={{ display: modal_user ? "block" : "none" }}>
                                    <div className="modal-dialog modal-lg modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title"><i className="fas fa-user-shield color-orange"></i> {sitemodeModal} Office Acount</h4>
                                                <i className="fe-x modal-close" onClick={() => {
                                                    setModal_user(false)
                                                }}></i>
                                            </div>
                                            <div className="modal-body">
                                                <form id="form-users" action="" method="POST" encType="multipart/form-data">
                                                    <div className="row mb-3">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="username">Username/Employee ID </label>
                                                                <input type="text" className="form-control" id="username" name="username" minLength={5} autoComplete='off' required />
                                                                <div className="invalid-feedback">อย่างน้อย 5 ตัวอักษร ควรจะเป็นรหัสพนักงาน และไม่ใช่ ADMIN</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="password">Password</label>
                                                                <input type="password" className="form-control" id="password-office" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" autoComplete='off' required />
                                                                <div className="invalid-feedback">อย่างน้อย 8 ตัวอักษร และประกอบไปด้วย ตัวพิมใหญ่อย่างน้อย 1 ตัว, ตัวพิมเล็กอย่างน้อย 1 ตัว, ตัวเลขอย่างน้อย 1 ตัว</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="confirmpassword">Confirm Password</label>
                                                                <input type="password" className="form-control" id="confirmpassword-office" name="confirmpassword" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" autoComplete='off' required />
                                                                <div className="invalid-feedback">อย่างน้อย 8 ตัวอักษร และประกอบไปด้วย ตัวพิมใหญ่อย่างน้อย 1 ตัว, ตัวพิมเล็กอย่างน้อย 1 ตัว, ตัวเลขอย่างน้อย 1 ตัว</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="firstname">Firstname</label>
                                                                <input type="text" className="form-control" id="firstname" name="firstname" minLength={2} autoComplete="off" required />
                                                                <div className="invalid-feedback">อย่างน้อย 2 ตัวอักษร</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="lastname">Lastname</label>
                                                                <input type="text" className="form-control" id="lastname" name="lastname" minLength={2} autoComplete="off" required />
                                                                <div className="invalid-feedback">อย่างน้อย 2 ตัวอักษร</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer">
                                                <button className="btn btn-light btn-sm" type="button" onClick={() => {
                                                    setModal_user(false)
                                                }}><i className="fe-x"></i> Cancel</button>
                                                <button className="btn btn-warning btn-sm" onClick={() => { api_handleSubmit() }}><i className="fe-save"></i> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalLoad
                            option={modal_load}
                        ></ModalLoad>
                        <ModalPopupMessage
                            callback_general={message => { showDialogCallback_General(message) }}
                            callback_confirm={message => { showDialogCallback_Confirm(message) }}
                            options={modal_popup}
                        />
                    </div>
                    {
                        modal_user ?
                            <div className="meya-backdrop"></div>
                            :
                            null
                    }
                </div>
            </div>
        </section>

    )
}
export default AccountOffice