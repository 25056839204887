import React from 'react';
import PropTypes from 'prop-types';
import './ModalPopup.css';

class ModalPopup extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            outsidedtect: false
        }
    }



    render() {
        return (
            <>
                {
                    this.props.options.enable === true && this.props.options.modaltype != "" ?
                        <>

                            <div className={'meya-modal-generic ' + this.props.options.modaltype + (this.state.outsidedtect ? " modal-chake" : "")} onClick={() => {
                                this.setState({
                                    outsidedtect: false
                                });
                            }}>
                                <div className="meya-modal-generic-body text-center" >
                                    <div className={"meya-modal-generic-header " + this.props.options.modaltype}>{this.props.options.headers}</div>
                                    <div className="meya-modal-generic-content mb-5">{this.props.options.message}</div>
                                    {
                                        this.props.options.modaltype === "modal-confirm" ?
                                            <div className={"d-md-flex justify-content-between"}>
                                                <div className="meya-modal-btn btn-warning" onClick={() => this.props.callback_confirm(this.props.options.confirmcode)}>Confirm</div>
                                                <div className="meya-modal-btn btn-light" onClick={() => this.props.callback_general('close')}>Cancel</div>
                                            </div>
                                            :
                                            <div className={"d-md-flex justify-content-center"}>
                                                <div className="meya-modal-btn btn-light my-2" onClick={() => this.props.callback_general('close')}>Close</div>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className='meya-backdrop-generic' onClick={() => {
                                this.setState({
                                    outsidedtect: true
                                });
                            }}></div>
                        </>
                        :
                        null
                }
            </>
        )
    }
}

ModalPopup.propTypes = {
    callback_general: PropTypes.func,
    callback_confirm: PropTypes.func
};

export default ModalPopup;