import React, { useState, useEffect, useRef } from 'react';
import {
    Link,
} from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import Flatpickr from "react-flatpickr";
import Papa from "papaparse";
const allowedExtensions = ["csv"];

function RefferenceParcel(props) {
    const inputBulk = useRef(null)
    const [filedata, setFileData] = useState([]);

    const [modal_import, setModal_import] = useState(false);
    const [coupon_list, setCoupon_list] = useState([]);
    const [coupon_selected, setCoupon_Selected] = useState({
        coupon_id: "",
        min_order: 0,
        discount: 0,
        expiredate: moment(new Date()).format('YYYY-MM-DD')
    });
    const [couponmode, setCouponmode] = useState('');
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });
    const [modal_coupon, setModal_coupon] = useState(false);

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        if (message === "import") {
            bulk_upload()
        } else if (message === "delete") {
            deleteCoupon()
        }
    }

    useEffect(() => {
        getCouponList()
    }, []);

    async function getCouponList() {
        setmodal_load({ enable: true, message: "Loading coupon list." })
        await sleep(500)

        const formData = new FormData();
        formData.append('filter', 'approved')

        axios.post(props.app_hostname + "/api/order/coupon/getlist", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setCoupon_list(response.data.coupon)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    function manageCoupon(mode, coupon_data) {
        setCouponmode(mode)
        if (mode === 'add') {
            setCoupon_Selected({
                coupon_id: "",
                min_order: 0,
                discount: 0,
                expiredate: moment(new Date()).format('YYYY-MM-DD')
            })
            document.getElementById('coupon_id').disabled = false
        } else {
            setCoupon_Selected({
                coupon_id: coupon_data.coupon_id,
                min_order: coupon_data.min_order,
                discount: coupon_data.discount,
                expiredate: moment(new Date(coupon_data.expiredate)).format('YYYY-MM-DD')
            })
            document.getElementById('coupon_id').disabled = true
        }
        setModal_coupon(true)
    }

    async function handleSubmit() {
        var formelement = document.getElementById('form-coupon')
        if (formelement.checkValidity() === false) {
            formelement.classList.add("was-validated");
        }
        else {
            setmodal_load({ enable: true, message: "Save coupon to database." })
            await sleep(500)

            formelement.classList.remove("was-validated");
            const formData = new FormData(formelement);
            formData.append('username', props.permitdata.username)

            coupon_selected.username = props.permitdata.username
            axios.post(props.app_hostname + "/api/order/coupon/" + couponmode, coupon_selected, {
                headers: {
                    Accept: 'application/json;',
                    'Content-Type': 'application/x-www-form-urlencoded;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        setCoupon_list(response.data.coupon)
                        setModal_coupon(false)
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'Success',
                            message: "Your coupon has been saved to database.",
                            enable: true
                        });
                    }
                    else {
                        setModal_coupon(false)
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: err.message,
                        enable: true
                    });
                });
        }
    }

    async function deleteCoupon() {
        setmodal_load({ enable: true, message: "Deleting coupon from database." })
        await sleep(500)

        const formData = new FormData();
        formData.append('coupon_id', coupon_selected.coupon_id)

        axios.post(props.app_hostname + "/api/order/coupon/delete", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setCoupon_list(response.data.coupon)
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'Success',
                        message: "Your coupon has been deleted from database.",
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function bulk_upload() {
        setmodal_load({ enable: true, message: "Uploading bulk coupon data to database." })
        await sleep(500)

        const formData = new FormData();
        formData.append("coupon_data", JSON.stringify(filedata));
        formData.append('username', props.permitdata.username)

        axios.post(props.app_hostname + "/api/order/coupon/import", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setCoupon_list(response.data.coupon)
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'Success',
                        message: "Your coupon has been imported to database.",
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
                setFileData([])
                setModal_import(false)
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setFileData([])
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    function clickBulk() {
        const inp = inputBulk.current
        inp.click()
    }

    const handleFileChange = (e) => {
        if (e.target.files.length) {
            const inputFile = e.target.files[0];

            if (inputFile) {
                Papa.parse(inputFile, {
                    complete: (result) => {
                        const unique = result.data.filter(
                            (obj, index) =>
                                result.data.findIndex((item) => item.coupon_id === obj.coupon_id && item.coupon_id.length === 9 && Number(item.min_order) > 0 && Number(item.discount) > 0) === index
                        );

                        setFileData(unique);
                        setModal_import(true)
                    },
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                });
            }
        }
    };

    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="fas fa-money-check-alt color-orange"></i> Coupon</h4>
                            <p className="mb-3">Manage your coupon world</p>
                            <hr className="new-section-xs mb-2" />
                            <div className="d-flex justify-content-start gap-3 mt-2">
                                <button className="btn btn-success btn-sm" onClick={() => { manageCoupon('add', {}) }}><i className="fas fa-plus"></i> Add New</button>
                                <button className="btn btn-info btn-sm" onClick={() => { clickBulk() }}><i className="fas fa-file-import"></i> Bulk Import  <input hidden type="file" accept='.csv' value='' ref={inputBulk} onChange={(e) => { handleFileChange(e) }} /></button>
                                <button className="btn btn-success btn-sm" onClick={() => { getCouponList() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-1 mb-md-4">
                        <h5>Coupon List <span>({coupon_list.length} Coupons)</span></h5>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered" >
                                <thead>
                                    <tr className="admintable-header">
                                        <th className="id-fixwidth">#</th>
                                        <th>Coupon ID</th>
                                        <th>Status</th>
                                        <th>Min. Order</th>
                                        <th>Discount</th>
                                        <th>Expire Date</th>
                                        <th>Used Date</th>
                                        <th>Create Date</th>
                                        <th>Payment ID</th>
                                        <th>Edit By</th>
                                        <th className="text-center action-fixwidth">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        coupon_list.map((item, index) => (
                                            <tr key={item.coupon_id}>
                                                <td><i className="fas fa-money-check-alt rows-icon "></i> {index + 1}</td>
                                                <td className="font-bold my-0 py-0">{item.coupon_id}</td>
                                                <td className={
                                                    item.isexpire ?
                                                        "font-bold text-danger"
                                                        :
                                                        item.coupon_status === 'Free' ?
                                                            "font-bold color-green"
                                                            :
                                                            "font-bold"
                                                }>
                                                    {
                                                        item.isexpire ?
                                                            "Expired"
                                                            :
                                                            item.coupon_status
                                                    }
                                                </td>
                                                <td className="font-bold my-0 py-0">{item.min_order}</td>
                                                <td>{item.discount}</td>
                                                <td>{moment(new Date(item.expiredate)).subtract(7, 'hours').format('YYYY-MMM-DD')}</td>
                                                <td>{moment(new Date(item.useddate)).subtract(7, 'hours').format('YYYY-MMM-DD HH:mm:ss')}</td>
                                                <td>{moment(new Date(item.createtime)).subtract(7, 'hours').format('YYYY-MMM-DD HH:mm:ss')}</td>
                                                <td>{item.payment_id}</td>
                                                <td>
                                                    {item.useredit}
                                                    <p className="my-0 py-0">{moment(new Date(item.edittime)).subtract(7, 'hours').format('YYYY-MMM-DD HH:mm:ss')}</p>
                                                </td>
                                                <td> {
                                                    item.isexpire === false && item.coupon_status === "Free" ?
                                                        <Link className="btn btn-icon btn-sm btn-success btn-hover btn-bordered me-1" title="Edit" href="#" onClick={() => { manageCoupon('edit', item) }}><i className="fas fa-pen"></i></Link>
                                                        :
                                                        null
                                                }
                                                    <Link className="btn btn-icon btn-sm btn-danger btn-hover btn-bordered" title="Delete" href="#" onClick={() => {
                                                        setCoupon_Selected(item);
                                                        setModal_popup({
                                                            modaltype: 'modal-confirm',
                                                            headers: 'Confirmation',
                                                            message: "Please click on confirm button to continue this operation.",
                                                            enable: true,
                                                            confirmcode: "delete",
                                                        });
                                                    }}><i className="fas fa-eraser"></i></Link>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="modal meya-overide" style={{ display: modal_coupon ? "block" : "none" }}>
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {
                                        couponmode === 'add' ?
                                            <h4 className="modal-title"><i className="fas fa-capsules color-orange"></i> Add Coupon</h4>
                                            :
                                            <h4 className="modal-title"><i className="fas fa-capsules color-orange"></i> Edit Coupon</h4>
                                    }
                                    <i className="fe-x modal-close" onClick={() => {
                                        setModal_coupon(false)
                                    }}></i>
                                </div>
                                <div className="modal-body">
                                    <form id="form-coupon" action="" method="POST" encType="multipart/form-data">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="coupon_id">Coupon ID</label>
                                                    <input type="text" className="form-control" id="coupon_id" name="coupon_id" minLength={9} maxLength={9} value={coupon_selected.coupon_id}
                                                        onChange={(e) => setCoupon_Selected({
                                                            ...coupon_selected,
                                                            coupon_id: e.target.value
                                                        })}
                                                        required />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="min_order">Min. Order</label>
                                                    <input type="number" className="form-control" id="min_order" name="min_order" min={50} step={1} value={coupon_selected.min_order}
                                                        onChange={(e) => setCoupon_Selected({
                                                            ...coupon_selected,
                                                            min_order: e.target.value
                                                        })}
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="discount">Discount</label>
                                                    <input type="number" className="form-control" id="discount" name="discount" min={1} step={1} value={coupon_selected.discount}
                                                        onChange={(e) => setCoupon_Selected({
                                                            ...coupon_selected,
                                                            discount: e.target.value
                                                        })}
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="expire">Expire Date</label>
                                                    <Flatpickr
                                                        options={{
                                                            minDate: "2017-01-01",
                                                            dateFormat: "Y-m-d",
                                                            time_24hr: true,
                                                        }}
                                                        value={new Date(coupon_selected.expiredate)}
                                                        onChange={([date]) => {
                                                            setCoupon_Selected({
                                                                ...coupon_selected,
                                                                expiredate: moment(new Date(date)).format('YYYY-MM-DD')
                                                            })
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-light  btn-sm" type="button" onClick={() => {
                                        setModal_coupon(false)
                                    }}><i className="fe-x"></i> Cancel</button>
                                    <button className="btn btn-warning btn-sm" onClick={() => { handleSubmit() }}><i className="fe-save"></i> Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal meya-overide " style={{ display: modal_import ? "block" : "" }}>
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className='py-0 my-0'>Coupon Bulk import Preview</h6>
                                    <i className="fe-x modal-close" onClick={() => {
                                        setModal_import(false)
                                    }}></i>
                                </div>
                                <div className="modal-body">
                                    <div className="table-responsive">
                                        <table className="table font-en" >
                                            <tbody>
                                                <tr className='bg-gray border-bottom'>
                                                    <td>#</td>
                                                    <td>Coupon ID</td>
                                                    <td>Min. Order</td>
                                                    <td>Discount</td>
                                                    <td>Expire Date</td>
                                                </tr>
                                                {
                                                    filedata.map((item, index) => (
                                                        <tr key={item.coupon_id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.coupon_id}</td>
                                                            <td>{item.min_order}</td>
                                                            <td>{item.discount}</td>
                                                            <td>{item.expiredate}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-light  btn-sm" type="button" onClick={() => {
                                        setModal_import(false);
                                        setFileData([])
                                    }}><i className="fe-x"></i> Cancel</button>
                                    <button className="btn btn-warning btn-sm" onClick={() => {
                                        setModal_popup({
                                            modaltype: 'modal-confirm',
                                            headers: 'Confirmation',
                                            message: "Please click on confirm button to continue this operation.",
                                            enable: true,
                                            confirmcode: "import",
                                        });
                                    }}><i className="fe-save"></i> Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modal_coupon || modal_import ?
                    <div className="meya-backdrop"></div>
                    :
                    null
            }
        </div>
    )
}

export default RefferenceParcel