import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Sidebar(props) {
    const location = useLocation();
    const [sidebar_selected, setSidebar_selected] = useState({
        order: false,
        extra: false,
        product: false,
        reference: false,
        accountshop: false,
        accountoffice: false,
        helpdesk: false,
        apisetting: false
    });

    const [Menuselected, setMenuselected] = useState({
        menu: "",
        submenu: ""
    });
    const [toggle_bit, settoggle_bit] = useState(0);

    useEffect(() => {
        const myArray = location.pathname.split("/");
        if (myArray.length > 2) {
            setMenuselected({
                menu: myArray[1],
                submenu: myArray[2]
            })
        } else {
            if (myArray.length == 2) {
                if (myArray[1] === "accountoffice") {
                    setMenuselected({
                        menu: "accountoffice",
                        submenu: "accountoffice"
                    })
                }
            }
        }
        removesidebar()
    }, [location.pathname]);

    useEffect(() => {
        var root = document.getElementById("mainroot");
        document.querySelectorAll(".nav-toggler").forEach((e) => {
            e.addEventListener("click", () => {
                if (window.innerWidth < 992 || (!root.classList.contains("mn--min") && !root.classList.contains("mn--max"))) {
                    root.classList.toggle("mn--show")
                } else {
                    if (root.classList.contains("mn--max")) {
                        setSidebar_selected({
                            order: false,
                            extra: false,
                            product: false,
                            reference: false,
                            accountshop: false,
                            accountoffice: false,
                            chat: false,
                            apisetting: false
                        })
                    }
                    if (root.classList.contains("mn--min")) {
                        settoggle_bit(1)
                    } else {
                        settoggle_bit(2)
                    }
                    root.classList.toggle("mn--min")
                    root.classList.toggle("mn--max");
                }
            });
        })
        window.addEventListener("resize", (event) => {
            if (window.innerWidth < 992) {
                if (root.classList.contains("mn--max")) {
                    root.classList.toggle("mn--max");
                }
            } else {
                if (!root.classList.contains("mn--min") && !root.classList.contains("mn--max")) {
                    root.classList.toggle("mn--min")
                }
                tm_menu = {
                    order: false,
                    extra: false,
                    product: false,
                    reference: false,
                    accountshop: false,
                    accountoffice: false,
                    helpdesk: false,
                    apisetting: false
                }
                setSidebar_selected(tm_menu)
            }
        });
        document.querySelectorAll(".sidebar-toggler").forEach((e) => {
            e.addEventListener("click", () => root.classList.toggle("sb--show"));
        })
        document.addEventListener("click", (e) => {
            if (e.target.classList.contains("root")) {
                if (!e.target.classList.contains('mininav-toggle')) {
                    root.classList.remove("mn--show")
                }
            }
            if (root.classList.contains("sb-stuck")) {
                root.classList.remove("sb--show")
            }
        });
        var tm_menu
        document.querySelectorAll(".mininav-toggle.nav-link").forEach((e) => {
            e.addEventListener("mouseover", (event) => {
                if (root.classList.contains("mn--min") && !root.classList.contains("mn--show")) {
                    var rp = event.target.className.split(" ");
                    const myArray = rp[0].split("-");
                    tm_menu = {
                        order: false,
                        extra: false,
                        product: false,
                        reference: false,
                        accountshop: false,
                        accountoffice: false,
                        helpdesk: false,
                        apisetting: false
                    }
                    tm_menu[myArray[2]] = true
                    setSidebar_selected(tm_menu)
                }
            });
        });
        document.querySelectorAll(".nav-item.has-sub").forEach((e) => {
            e.addEventListener("mouseleave", (event) => {
                if (root.classList.contains("mn--min") && !root.classList.contains("mn--show")) {
                    var rp = event.target.className.split(" ");
                    const myArray = rp[0].split("-");
                    tm_menu = {
                        order: false,
                        extra: false,
                        product: false,
                        reference: false,
                        accountshop: false,
                        accountoffice: false,
                        helpdesk: false,
                        apisetting: false
                    }
                    setSidebar_selected(tm_menu)
                }
            });
        });
    }, [])

    useEffect(() => {
        if (toggle_bit === 1) {
            defaultoggle()
        }
    }, [toggle_bit]);

    function removesidebar() {
        var root = document.getElementById("mainroot");
        root.classList.remove("mn--show")
        root.classList.remove("sb--show")
    }

    function getmenuclass(url_expected, menu_toggle) {
        if (menu_toggle === true) {
            if (Menuselected.menu === url_expected) {
                return "active collapse"
            } else {
                return "collapse"
            }
        } else {
            if (Menuselected.menu === url_expected) {
                return "active collapsed"
            } else {
                return "collapsed"
            }
        }
    }

    function toggle_menu(keys) {
        var root = document.getElementById("mainroot");
        if (!root.classList.contains("mn--min") || (root.classList.contains("mn--min") && root.classList.contains("mn--show"))) {
            var x = {
                order: false,
                extra: false,
                product: false,
                reference: false,
                accountshop: false,
                accountoffice: false,
                helpdesk: false,
                apisetting: false
            }
            x[keys] = true
            setSidebar_selected(x)
        }
    }

    function defaultoggle() {
        const myArray = location.pathname.split("/");
        if (myArray.length > 2) {
            toggle_menu(myArray[1])
        } else {
            if (myArray.length == 2) {
                toggle_menu(myArray[1])
            }
        }
    }

    return (

        <nav id="mainnav-container" className="mainnav">
            <div className="mainnav__inner">
                <div className="mainnav__top-content scrollable-content pb-5">
                    <div className="mainnav__categoriy py-3">
                        <h6 className="mainnav__caption mt-0 px-3">Select Menu</h6>
                        <ul className="mainnav__menu nav flex-column">
                            <li className="nav-item has-sub py-1">
                                <Link className={"nav-mn-order mininav-toggle nav-link " + getmenuclass("order", sidebar_selected.order)} onClick={() => { toggle_menu("order") }} ><i className="far fa-list-alt fs-6 me-2" style={{ minWidth: "18px" }}></i>
                                    <span className="nav-label ms-1">Order</span>
                                </Link>

                                <ul className={"mx-3 mininav-content nav collapse " + (sidebar_selected.order === true ? " show" : " ")} style={{ transform: "translate3d(50px, 0px, 0px)" }}>
                                    <li className="nav-item">
                                        <span className="nav-label-header-pop ms-1" style={{ color: "white" }}>Order</span>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/order/payslip" className={"nav-link " + (Menuselected.submenu === "payslip" ? " active" : "")}>Pay Slip Validation</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/order/refund" className={"nav-link " + (Menuselected.submenu === "refund" ? " active" : "")}>Buyer Refund Pending</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/order/income" className={"nav-link " + (Menuselected.submenu === "income" ? " active" : "")}>Seller Income Pending</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/order/tracking" className={"nav-link " + (Menuselected.submenu === "tracking" ? " active" : "")}>Order Tracking</Link>
                                    </li>

                                </ul>
                            </li>
                            <li className="nav-item has-sub py-1">
                                <Link className={"nav-mn-extra nav-link mininav-toggle " + getmenuclass("extra", sidebar_selected.extra)} onClick={() => { toggle_menu("extra") }}><i className="fas fa-star fs-6 me-2" style={{ minWidth: "18px" }}></i>
                                    <span className="nav-label ms-1">Extra</span>
                                </Link>
                                <ul className={"mininav-content nav collapse mx-3 " + (sidebar_selected.extra === true ? " show" : "")} style={{ transform: "translate3d(50px, 0px, 0px)" }}>
                                    <li className="nav-item">
                                        <span className="nav-label-header-pop ms-1" style={{ color: "white" }}>Extra</span>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/extra/coupon" className={"nav-link " + (Menuselected.submenu === "coupon" ? " active" : "")}>Coupon</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item has-sub py-1">
                                <Link className={"nav-mn-product nav-link mininav-toggle " + getmenuclass("product", sidebar_selected.product)} onClick={() => { toggle_menu("product") }}><i className="fas fa-capsules fs-6 me-2" style={{ minWidth: "18px" }}></i>
                                    <span className="nav-label mininav-content ms-1">Product</span>
                                </Link>
                                <ul className={"mininav-content nav collapse mx-3 " + (sidebar_selected.product === true ? " show" : "")} style={{ transform: "translate3d(50px, 0px, 0px)" }}>
                                    <li className="nav-item">
                                        <span className="nav-label-header-pop ms-1" style={{ color: "white" }}>Product</span>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/product/category" className={"nav-link " + (Menuselected.submenu === "category" ? " active" : "")}>Product Category</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/product/item" className={"nav-link " + (Menuselected.submenu === "item" ? " active" : "")}>Product Item Table</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/product/itempreview" className={"nav-link " + (Menuselected.submenu === "itempreview" ? " active" : "")}>Product Item Preview</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item has-sub py-1">
                                <Link className={"nav-mn-reference nav-link mininav-toggle " + getmenuclass("reference", sidebar_selected.reference)} onClick={() => { toggle_menu("reference") }}><i className="fas fa-database fs-6 me-2" style={{ minWidth: "18px" }}></i>
                                    <span className="nav-label mininav-content ms-1">Reference</span>
                                </Link>
                                <ul className={"mininav-content nav collapse mx-3 " + (sidebar_selected.reference === true ? " show" : "")} style={{ transform: "translate3d(50px, 0px, 0px)" }}>
                                    <li className="nav-item">
                                        <span className="nav-label-header-pop ms-1" style={{ color: "white" }}>Reference</span>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/reference/parcel" className={"nav-link " + (Menuselected.submenu === "parcel" ? " active" : "")}>Parcel</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/reference/factor" className={"nav-link " + (Menuselected.submenu === "factor" ? " active" : "")}>Factor</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item has-sub py-1">
                                <Link className={"nav-mn-accountshop nav-link mininav-toggle " + getmenuclass("accountshop", sidebar_selected.accountshop)} onClick={() => { toggle_menu("accountshop") }}><i className="fas fa-store-alt fs-6 me-2" style={{ minWidth: "18px" }}></i>
                                    <span className="nav-label mininav-content ms-1">Shop Account</span>
                                </Link>
                                <ul className={"mininav-content nav collapse mx-3 " + (sidebar_selected.accountshop === true ? " show" : "")} style={{ transform: "translate3d(50px, 0px, 0px)" }}>
                                    <li className="nav-item">
                                        <span className="nav-label-header-pop ms-1" style={{ color: "white" }}>Shop Account</span>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/accountshop/accountapproved" className={"nav-link " + (Menuselected.submenu === "accountapproved" ? " active" : "")}>Approved Shop</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/accountshop/accountpending" className={"nav-link " + (Menuselected.submenu === "accountpending" ? " active" : "")}>Pending Shop</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/accountshop/accountsuspended" className={"nav-link " + (Menuselected.submenu === "accountsuspended" ? " active" : "")}>Suspended Shop</Link>
                                    </li>
                                </ul>
                            </li>
                            {
                                props.permitdata.role === 'admin' ?
                                    <li className="nav-item py-1">
                                        <Link to="/accountoffice" className={"nav-link mininav-toggle " + (Menuselected.menu === "accountoffice" ? "active" : null)} onClick={() => { toggle_menu("accountoffice") }}><i className="fas fa-user-shield fs-6 me-2" style={{ minWidth: "18px" }}></i>
                                            <span className="nav-label mininav-content ms-1" style={{ transform: "translate3d(50px, 0px, 0px)" }}>Office Account</span>
                                        </Link>
                                    </li>
                                    :
                                    null
                            }
                            <li className="nav-item has-sub py-1">
                                <Link className={"nav-mn-helpdesk nav-link mininav-toggle " + getmenuclass("helpdesk", sidebar_selected.accountshop)} onClick={() => { toggle_menu("helpdesk") }}><i className="fas fa-headset fs-6 me-2 d-flex" style={{ minWidth: "18px" }}></i>
                                    <span className="nav-label mininav-content ms-1">Help Desk </span>
                                </Link>
                                <ul className={"mininav-content nav collapse mx-3 " + (sidebar_selected.helpdesk === true ? " show" : "")} style={{ transform: "translate3d(50px, 0px, 0px)" }}>
                                    <li className="nav-item">
                                        <span className="nav-label-header-pop ms-1" style={{ color: "white" }}>Help Desk </span>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/helpdesk/chat" className={"nav-link " + (Menuselected.submenu === "chat" ? " active" : "")}>Chat</Link>
                                    </li>
                                </ul>
                            </li>
                            {
                                props.permitdata.role === 'admin' ?
                                    <li className="nav-item has-sub py-1">
                                        <Link className={"nav-mn-apisetting nav-link mininav-toggle " + getmenuclass("apisetting", sidebar_selected.apisetting)} onClick={() => { toggle_menu("apisetting") }}><i className="fas fa-tools fs-6 me-2" style={{ minWidth: "18px" }}></i>
                                            <span className="nav-label mininav-content ms-1">API Setting</span>
                                        </Link>
                                        <ul className={"mininav-content nav collapse mx-3 " + (sidebar_selected.apisetting === true ? " show" : "")} style={{ transform: "translate3d(50px, 0px, 0px)" }}>
                                            <li className="nav-item">
                                                <span className="nav-label-header-pop ms-1" style={{ color: "white" }}>API Setting</span>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/apisetting/sms" className={"nav-link " + (Menuselected.submenu === "sms" ? " active" : "")}>SMS</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/apisetting/email" className={"nav-link " + (Menuselected.submenu === "email" ? " active" : "")}>Email</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/apisetting/delivery" className={"nav-link " + (Menuselected.submenu === "delivery" ? " active" : "")}>Delivery</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    :
                                    null
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Sidebar