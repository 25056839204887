

export function orderStatus(order, viewmode) {
    if (order.order_status === 'IV_Req') {
        if (order.api_paymentstatus === 'Success') {
            return <div className="text-success text-left text-md-right">รอผู้ขายตอบรับคำสั่งซื้อ</div>;
        } else if (order.api_paymentstatus === 'Fail') {
            return <div className="text-danger text-left text-md-right">ชำระเงินไม่สำเร็จ</div>;
        } else if (order.api_paymentstatus === 'Cancel') {
            return <div className="text-warning text-left text-md-right">ลูกค้ายกเลิกการชำระเงิน</div>;
        } else if (order.api_paymentstatus === 'Error') {
            return <div className="text-danger text-left text-md-right">เกิดข้อผิดพลาด</div>;
        } else if (order.api_paymentstatus === 'Transaction Pending') {
            return <div className="text-primary text-left text-md-right">รอการชำระค่าสินค้า</div>;
        } else if (order.api_paymentstatus === 'Void Success') {
            return <div className="text-success text-left text-md-right">Void สำเร็จ </div>;
        } else if (order.api_paymentstatus === 'Refund Success') {
            return <div className="text-success text-left text-md-right">Refund สำเร็จ</div>;
        } else if (order.api_paymentstatus === 'Request to Refund') {
            return <div className="text-primary text-left text-md-right">กำลังขอ Refund</div>;
        } else if (order.api_paymentstatus === 'Settlement Success') {
            return <div className="text-success text-left text-md-right">โอนเงินให้ร้านค้าสำเร็จ</div>;
        } else if (order.api_paymentstatus === 'Void Fail') {
            return <div className="text-danger text-left text-md-right">Void ไม่สำเร็จ</div>;
        } else if (order.api_paymentstatus === 'Refund Fail') {
            return <div className="text-danger text-left text-md-right">Refund ไม่สำเร็จ</div>;
        } else {
            return <div className="text-danger text-left text-md-right">ไม่ทราบสถานะการชำระเงิน</div>
        }
    } else if (order.order_status === 'IV_Cancel') {
        return <div className="text-warning text-left text-md-right">ผู้ซื้อยกเลิกคำสั่งซื้อ</div>
    } else if (order.order_status === 'IV_Exp') {
        return <div className="text-danger text-left text-md-right">หมดอายุชำระเงิน</div>
    } else if (order.order_status === 'IV_C_Cancel') {
        return <div className="text-warning text-left text-md-right">ผู้ซื้อยกเลิกคำสั่งซื้อ</div>
    } else if (order.order_status === 'IV_V_Cancel') {
        return <div className="text-warning text-left text-md-right">ผู้ขายยกเลิกคำสั่งซื้อ</div>
    } else if (order.order_status === 'IV_Refunded') {
        return <div className="text-primary text-left text-md-right">คืนเงินที่ผู้ซื้อยกเลิกสำเร็จ</div>
    } else if (order.order_status === 'PC_Prepare') {
        return <div className="text-success text-left text-md-right">ผู้ขายตอบรับคำสั่งซื้อแล้ว</div>
    } else if (order.order_status === 'PC_C_Cancel_Req') {
        return <div className="text-warning text-left text-md-right">ผู้ซื้อขอยกเลิกคำสั่งซื้อ</div>
    } else if (order.order_status === 'PC_C_Cancel_AC') {
        return <div className="text-primary text-left text-md-right">ผู้ขายตอบรับการยกเลิกคำสั่งซื้อ</div>
    } else if (order.order_status === 'PC_V_Cancel') {
        return <div className="text-warning text-left text-md-right">ผู้ขายยกเลิกคำสั่งซื้อ</div>
    } else if (order.order_status === 'PC_Book') {
        return <div className="text-primary text-left text-md-right">ผู้ขายกำลังเตรียมพัสดุ</div>
    } else if (order.order_status === 'PC_C_Book_Cancel_Req') {
        return <div className="text-warning text-left text-md-right">ผู้ซื้อขอยกเลิกคำสั่งซื้อ</div>
    } else if (order.order_status === 'PC_C_Book_Cancel_AC') {
        return <div className="text-primary text-left text-md-right">ผู้ขายตอบรับการยกเลิกคำสั่งซื้อ</div>
    } else if (order.order_status === 'PC_BookConfirm') {
        if (order.shipping_status === 'pending') {
            return <div className="text-primary text-left text-md-right">จองระบบขนส่งแล้ว</div>
        } else if (order.shipping_status === 'booking') {
            return <div className="text-primary text-left text-md-right">รอขนส่งเข้ารับพัสดุ</div>
        } else if (order.shipping_status === 'shipping') {
            return <div className="text-success text-left text-md-right">ขนส่งเข้ารับพัสดุแล้ว</div>
        } else if (order.shipping_status === 'package_detail') {
            return <div className="text-primary text-left text-md-right">อัพเดทข้อมูลพัสดุ</div>
        } else if (order.shipping_status === 'problem') {
            return <div className="text-danger text-left text-md-right">พัสดุมีปัญหา</div>
        } else if (order.shipping_status === 'complete') {
            return <div className="text-success text-left text-md-right">จัดส่งสำเร็จ</div>
        } else if (order.shipping_status === 'return') {
            return <div className="text-danger text-left text-md-right">พัสดุถูกตีกลับต้นทาง</div>
        } else {
            return <div className="text-danger text-left text-md-right">ไม่ทราบสถานะขนส่ง</div>
        }
    } else if (order.order_status === 'PC_RCV') {
        return <div className="text-success text-left text-md-right">ผู้ซื้อรับสินค้าแล้ว</div>
    } else if (order.order_status === 'PC_RCV_TransBack') {
        return <div className="text-success text-left text-md-right">ผู้ขายรับพัสดุตีกลับแล้ว</div>
    } else if (order.order_status === 'PC_RTN_Refunded') {
        return <div className="text-warning text-left text-md-right">โอนส่วนต่างคืนสินค้าแล้ว</div>
    } else if (order.order_status === 'PC_RTN_Prepare') {
        return <div className="text-warning text-left text-md-right">ผู้ซื้อเตรียมคืนสินค้า</div>
    } else if (order.order_status === 'PC_RTN_Req') {
        return <div className="text-warning text-left text-md-right">ผู้ซื้อขอคืนสินค้า</div>
    } else if (order.order_status === 'PC_RTN_AC') {
        return <div className="text-warning text-left text-md-right">ผู้ขายตอบรับการคืนสินค้า</div>
    } else if (order.order_status === 'PC_RTN_Book') {
        return <div className="text-primary text-left text-md-right">ผู้ซื้อเตรียมเตรียมพัสดุส่งคืน</div>
    } else if (order.order_status === 'PC_RTN_BookConfirm') {
        if (order.return_status === 'pending') {
            return <div className="text-primary text-left text-md-right">จองระบบขนส่งแล้ว</div>
        } else if (order.return_status === 'booking') {
            return <div className="text-primary text-left text-md-right">รอขนส่งเข้ารับพัสดุ</div>
        } else if (order.return_status === 'shipping') {
            return <div className="text-success text-left text-md-right">ขนส่งเข้ารับพัสดุแล้ว</div>
        } else if (order.return_status === 'package_detail') {
            return <div className="text-primary text-left text-md-right">อัพเดทข้อมูลพัสดุ</div>
        } else if (order.return_status === 'problem') {
            return <div className="text-danger text-left text-md-right">พัสดุมีปัญหา</div>
        } else if (order.return_status === 'complete') {
            return <div className="text-success text-left text-md-right">จัดส่งสำเร็จ</div>
        } else if (order.return_status === 'return') {
            return <div className="text-danger text-left text-md-right">พัสดุถูกตีกลับต้นทาง</div>
        } else {
            return <div className="text-danger text-left text-md-right">ไม่ทราบสถานะขนส่งการคืน</div>
        }
    } else if (order.order_status === 'PC_RTN_RCV') {
        return <div className="text-warning text-left text-md-right">ผู้ขายรับสินค้าคืนแล้ว</div>
    } else if (order.order_status === 'PC_RTN_Refunded') {
        return <div className="text-warning text-left text-md-right">โอนส่วนต่างคืนสินค้าแล้ว</div>
    } else {
        return <div className="text-danger text-left text-md-right">ไม่ทราบสถานะ</div>
    }
}
