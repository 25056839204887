import React, { useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import '../CustomModal.css';
import '../ImageGallery.css';
import '../CardMyCart.css';
import ImageGallery from "react-image-gallery";
import dayTh from "dayjs/locale/th";
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);
dayjs.locale(dayTh);

function OrderPaySlip(props) {
    const [modal_images_gallery, setModal_images_gallery] = useState(false);
    const [images_gallery, setImages_gallery] = useState([])

    const [modal_order, setModal_order] = useState(false);
    const [order_group, setOrder_group] = useState([]);
    const [order_list, setOrder_list] = useState([]);
    const [payslip_list, setPayslip_list] = useState([]);
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        api_cashrecieve(message)
    }

    useEffect(() => {
        api_getPaySlipList()
    }, []);

    useEffect(() => {
        const unique = order_list.filter(
            (obj, index) =>
                order_list.findIndex((item) => item.payment_id === obj.payment_id) === index
        );
        setOrder_group(unique)
    }, [order_list]);

    function filteroneorder_deliver(payment_id) {
        var item = order_list.find(item => { return item.payment_id === payment_id })
        if (item !== undefined) {
            return <div>
                <div className="d-block text-success mt-1">ที่อยู่ในการจัดส่ง</div>
                <table className='payment-table'>
                    <tbody className="d-block mt-0">
                        <tr className="">
                            <td width={"160px"} className="mt-0 mb-0">ผู้รับ</td>
                            <td className="mt-0 mb-0">{item.shipping_recievename}</td>
                        </tr>
                        <tr className="">
                            <td className="mt-0 mb-0">เบอร์โทรศัพย์</td>
                            <td className="mt-0 mb-0">{item.shipping_telno}</td>
                        </tr>
                        <tr className="">
                            <td className="mt-0 mb-0">ที่อยู่ในการจัดส่ง</td>
                            <td className="mt-0 mb-0">{item.shipping_address} {item.shipping_district} {item.shipping_state} {item.shipping_province} {item.shipping_postcode}</td>
                        </tr>
                        <tr className="">
                            <td className="mt-0 mb-0">ขนส่งโดย</td>
                            <td className="mt-0 mb-0">{item.courier_fullname}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
        return null
    }

    async function api_getPaySlipList() {
        setmodal_load({ enable: true, message: "Loading payslip validation pending list." })
        await sleep(500)

        const formData = new FormData();

        axios.post(props.app_hostname + "/api/order/admin/slip/getall", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setPayslip_list(response.data.payslip)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_readfile(payment) {
        setmodal_load({ enable: true, message: "Loading slip files." })
        await sleep(500)

        const formData = new FormData();
        formData.append('payment_id', payment.payment_id)

        axios.post(props.app_hostname + "/api/order/admin/slip/getimage", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setImages_gallery(response.data.files)
                    if (response.data.files.length > 0) {
                        setModal_images_gallery(true)
                    } else {
                        setModal_images_gallery(false)
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: "Files not found",
                            enable: true
                        });
                    }
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_images_gallery(false)
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_getorderbypayment(payment) {
        setmodal_load({ enable: true, message: "Loading order data." })
        await sleep(500)

        setOrder_group([])
        setOrder_list([])

        const formData = new FormData();
        formData.append('payment_id', payment.payment_id)

        axios.post(props.app_hostname + "/api/order/admin/slip/getorderbypayment", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setOrder_list(response.data.order)
                    setModal_order(true)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_order(false)
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_cashrecieve(payment) {
        setmodal_load({ enable: true, message: "Payment updating." })
        await sleep(500)

        const formData = new FormData();
        formData.append('payment_id', payment.payment_id)

        var shoplist = []
        var buyer = ""
        order_list.forEach(order => {
            if (order.payment_id === payment.payment_id) {
                shoplist.push(order.seller_shopid)
                buyer = order.buyer_shopid
            }
        })
        formData.append('shoplist', shoplist.join("##"))
        formData.append('buyer', buyer)

        axios.post(props.app_hostname + "/api/order/admin/slip/cashrecieve", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                setModal_order(false)
                if (response.data.status === 'OK') {

                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
                if (response.data.reload !== undefined) {
                    if (response.data.reload === true) {
                        setOrder_list(order_list.filter(function (item) {
                            return item.payment_id !== response.data.payment_id
                        }))
                    }
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_order(false)
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="far fa-newspaper color-orange"></i> Pay Slip Validation</h4>
                            <p className="mb-3">Validate all pay slip submited</p>
                            <hr className="new-section-xs mb-2" />
                            <div className="d-flex justify-content-start gap-3 mt-2">
                                <button className="btn btn-success btn-sm" onClick={() => { api_getPaySlipList() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-1 mb-md-4">
                        <h5>Pay Slip Submited List <span>({payslip_list.length} Invoice)</span></h5>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered" >
                                <thead>
                                    <tr className="admintable-header">
                                        <th className="id-fixwidth">#</th>
                                        <th>Invoice ID</th>
                                        <th>Invoice Date</th>
                                        <th>Buyer Name</th>
                                        <th>Sub Total</th>
                                        <th>Shipping</th>
                                        <th>Discount</th>
                                        <th>Total</th>
                                        <th className="text-center action-fixwidth">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        payslip_list.map((item, index) => (
                                            <tr key={item.payment_id}>
                                                <td><i className="far fa-newspaper rows-icon "></i> {index + 1}</td>
                                                <td className="font-bold my-0 py-0">{item.payment_id}</td>
                                                <td><p className="my-0 py-0">{moment(new Date(item.createtime)).subtract(7, 'hours').format('YYYY-MM-DD HH:mm:ss')}</p></td>
                                                <td className="font-bold my-0 py-0">
                                                    {item.buyer_shopname}
                                                    <p className="my-0 py-0">{item.buyer_shopprovince}</p>
                                                </td>
                                                <td>{item.summary_subtotal}</td>
                                                <td>{item.summary_shipping_markup}</td>
                                                <td>{item.summary_discount}</td>
                                                <td>{item.summary_total}</td>
                                                <td>
                                                    <Link className="btn btn-icon btn-sm btn-success btn-hover btn-bordered me-1" title="Edit" onClick={() => { api_getorderbypayment(item) }}><i className="fas fa-pen"></i></Link>
                                                    <Link className="btn btn-icon btn-sm btn-success btn-hover btn-bordered" title="Slip View" onClick={() => { api_readfile(item) }}><i className="fas fa-file"></i></Link>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <div className="modal-payment" style={{ display: modal_order ? "block" : "none" }}>
                <div className='modal-payment-wrapper'>
                    {
                        order_group.length > 0 ?
                            order_group.map((mypayment, index0) => (
                                <div className="col-md-12 mb-md-2 mb-lg-0" key={mypayment.payment_id}>
                                    <div className="card-mycart mb-2">
                                        <div className="card-mycart-header py-1">
                                            <div className="d-block d-md-flex justify-content-between py-0 flex-wrap">
                                                <div className="d-block">
                                                    <div className="card-mycart-title mb-0">หมายเลขชำระ : {mypayment.payment_id}</div>
                                                    <div className="text-danger font-size-14 mt-0">ต้องชำระภายใน {dayjs(new Date(mypayment.expiretime)).subtract(7, 'hours').format('DD MMM BBBB HH:mm')}</div>
                                                </div>
                                                <div className="d-block">
                                                    {
                                                        mypayment.payment_method === "cash" ?
                                                            <div className="text-danger font-size-14 mt-0">ชำระแบบ โอนเงิน</div>
                                                            : mypayment.payment_method === "promtpay" ?
                                                                <div className="text-danger font-size-14 mt-0">ชำระแบบ PromtPay</div>
                                                                : mypayment.payment_method === "creditcard" ?
                                                                    <div className="text-danger font-size-14 mt-0">ชำระแบบ บัตรเครดิต/บัตรเดบิต</div>
                                                                    :
                                                                    <div className="font-size-14 mt-0">ไม่ทราบ</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-mycart-body">
                                            <div className="d-block">
                                                {
                                                    order_list.length > 0 ?
                                                        order_list.map((order, index0) => (
                                                            order.payment_id === mypayment.payment_id ?
                                                                <div className={"d-block pt-1 mb-2 " + (index0 > 0 ? "border-top" : "")} key={order.order_id}>
                                                                    <div className="d-block d-md-flex justify-content-between py-0 flex-wrap mb-0">
                                                                        <div className="d-block">
                                                                            <div className="card-mycart-title"><i className="font-size-14 fas fa-user-tag text-info "></i> ผู้ขาย : {order.seller_shopname}</div>
                                                                            <p className="card-mycart-subtitle mb-0">จังหวัด {order.seller_shopprovince}</p>
                                                                        </div>
                                                                        <div className="d-block">
                                                                            <div className="text-left text-md-right  mb-0 pb-0">คำสั่งซื้อ {order.order_id}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-block d-md-flex justify-content-between pt-0 pb-2 flex-wrap ">
                                                                        <div className="d-block">
                                                                            {
                                                                                order.orderitem !== null ?
                                                                                    order.orderitem.map((orderitems, index1) => (
                                                                                        <div className={"d-block d-md-flex py-1 " + (index1 > 0 ? "border-top" : "")} key={orderitems.orderitem_id}>
                                                                                            <div className="d-flex">
                                                                                                <div className="mycart-box-image"><img className="img-fluid max-width-100 p-1 border border-color-1" src={"https://filestore.meya.co.th/productitem/" + `${orderitems.productitem_id}/` + `${orderitems.productitem_id}` + "-1-" + orderitems.img1_key + ".jpg"} alt="Image Description" /></div>
                                                                                                <div className='d-block product-detail'>
                                                                                                    <div className="mycart-box-productname py-0">{orderitems.productitem_name}</div>
                                                                                                    <div className="mycart-box-productinter py-0">{orderitems.productitem_inter}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="mycart-box-pricelist">
                                                                                                <table className='payment-table'>
                                                                                                    <tbody className="d-block mt-0">
                                                                                                        {
                                                                                                            orderitems.orderitem_pricelist !== null ?
                                                                                                                orderitems.orderitem_pricelist.map((pricelist, index1) => (
                                                                                                                    <tr className="mycart-table-pricelist-row py-0" key={pricelist.pricelist_id} style={{ paddingTop: "0px" }}>
                                                                                                                        <td className="product-unit">ชุด {pricelist.unit} ชิ้น</td>
                                                                                                                        <td className="product-stock">Exp. {dayjs(new Date(pricelist.expire)).format('DD MMM BBBB')}</td>
                                                                                                                        <td className="product-quantity">x {pricelist.select}</td>
                                                                                                                        <td className="product-subtotal">฿ {pricelist.subtotal.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}</td>
                                                                                                                    </tr>
                                                                                                                ))
                                                                                                                :
                                                                                                                null
                                                                                                        }
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>

                                                                        <div className="d-block mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                                                            <div className="d-flex justify-content-between py-0 mt-2">
                                                                                <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                                                <div className="table-summary-cellt">฿ {Number(order.shop_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between py-0">
                                                                                <div className="table-summary-cell">ค่าขนส่ง</div>
                                                                                <div className="table-summary-cell aligh-right">฿ {Number(order.shop_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                                                <div className="table-summary-cell">ยอดรวม</div>
                                                                                <div className="table-summary-cell aligh-right">฿ {Number(order.shop_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        ))
                                                        :
                                                        null
                                                }
                                                <div className="d-flex justify-content-between gap-2 py-2 border-top border-bottom">
                                                    <div className='d-block '>
                                                        <div className="text-danger mb-0" style={{ fontSize: "14px" }}>สรุปยอดที่ต้องชำระ</div>
                                                    </div>
                                                    <div className="d-block mb-5 mb-md-2" style={{ minWidth: "250px" }}>
                                                        <div className="d-flex justify-content-between py-0">
                                                            <div className="table-summary-cell">มูลค่าสินค้า</div>
                                                            <div className="table-summary-cellt">฿ {Number(mypayment.summary_subtotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between py-0">
                                                            <div className="table-summary-cell">ค่าขนส่ง</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_shipping_markup).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-between py-0">
                                                            <div className="table-summary-cell">ส่วนลด</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_discount).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                        {
                                                            mypayment.payment_method === "creditcard" ?
                                                                <>
                                                                    <div className="d-flex justify-content-between py-0 border-bottom text-success">
                                                                        <div className="table-summary-cell">ยอดรวม</div>
                                                                        <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between py-0">
                                                                        <div className="table-summary-cell">ค่าธรรมเนียมชำระผ่านบัตร</div>
                                                                        <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_creditcharge).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                        <div className="d-flex justify-content-between py-0 border-bottom text-danger">
                                                            <div className="table-summary-cell">ยอดที่ต้องชำระ</div>
                                                            <div className="table-summary-cell aligh-right">฿ {Number(mypayment.summary_net).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    filteroneorder_deliver(mypayment.payment_id)
                                                }

                                                <div className="d-flex justify-content-between gap-2 mt-4 ">
                                                    <button type="button" className="btn btn-sm btn-danger"
                                                        onClick={() => {
                                                            setModal_popup({
                                                                modaltype: 'modal-confirm',
                                                                headers: 'Confirmation',
                                                                message: "Please click on confirm button to continue this operation.",
                                                                enable: true,
                                                                confirmcode: mypayment,
                                                            });
                                                        }}>ได้รับชำระเเล้ว</button>
                                                    <button type="button" className="btn btn-sm btn-light"
                                                        onClick={() => {
                                                            setModal_order(false)
                                                            setOrder_group([])
                                                            setOrder_list([])
                                                        }}>ปิดหน้าต่าง</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            null
                    }
                </div>
            </div>

            {
                modal_images_gallery ?
                    <>
                        <div className='image-gallery-wrapper'>
                            <div className='image-gallery-inner'>
                                <div className='image-gallery-close' onClick={() => setModal_images_gallery(false)}><i className='fe-x'></i> </div>
                                <ImageGallery items={images_gallery}></ImageGallery>
                            </div>
                        </div>
                    </>
                    :
                    null
            }
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modal_images_gallery || modal_order ?
                    <div className="meya-backdrop"></div>
                    :
                    null
            }
        </div>
    )
}

export default OrderPaySlip