import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CardProduct.css';

class CardProductDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricearray: this.props.shopitem.pricetable
        }
    }

    getpricearray() {
        if (this.state.pricearray != undefined) {
            var rows = this.state.pricearray.split("##")
            if (rows.length > 0) {
                var items = rows[0].split("**")
                if (items.length >= 6) {
                    return items[1]
                }
            }
        }
        return ""
    }

    render() {
        return (
            <div className="flex-box " onClick={() => this.props.callback_editproduct(this.props.shopitem)}>
                <div className="ant-card ant-card-bordered u-unfold--css-animation fadeInRight h-100" style={{ animationDuration: "800ms", left: "0px" }}>
                    <div className="bg-image">
                        <img className="img-fluid mb-0" src={"https://api.meya.co.th/api/product/file/productitem/" + this.props.shopitem.productitem_id + "/" + this.props.shopitem.productitem_id + "-1-" + this.props.shopitem.img1_key + ".jpg"}
                            alt="Meya Shop Items Image" />
                    </div>

                    <div className="card-body pb-2 pt-1">
                        <div className="shopitem-info">
                            <div className="ant-card-info">
                                <h5 className="ant-card-text truncate-1 text-secondary">{this.props.shopitem.productitem_id}</h5>
                                <h5 className="ant-card-title truncate-1">{this.props.shopitem.productitem_name}</h5>
                                <p className="ant-card-text truncate-1">{this.props.shopitem.productitem_inter}</p>
                            </div>

                            <div className="ant-card-firstunit mb-0 mt-0 py-0 font-th">ราคาต่อ 10 ชิ้น</div>
                            <div className="d-flex justify-content-start gap-3 mt-0 py-0">
                                {
                                    this.props.shopitem.first_pricenew > 0 ?
                                        <>
                                            <ins className="font-size-20 text-primary font-weight-bold text-decoration-none mt-0 py-0 font-th">฿ {this.props.shopitem.first_pricenew}</ins>
                                            <del className="font-size-14 text-gray-5  bottom-100 mt-0 font-th">฿ {this.props.shopitem.first_pricebase}</del>
                                        </>
                                        :
                                        <>
                                            <ins className="font-size-20 text-gray-6 text-decoration-none font-th">฿ {this.props.first_pricebase}</ins>
                                            <del className="font-size-16 text-gray-6 font-weight-bold"></del>
                                        </>
                                }
                            </div>
                            {
                                this.props.show_shop == true ?
                                    <>
                                        <div className="d-flex justify-content-between">
                                            <div className="ant-card-shopname mb-0 py-0 text-dark font-th">มียา สโตร์</div>
                                        </div>
                                        <div className="ant-card-province text-gray-5 font-th mb-0 mt-0 py-0 "><i className="fe-map-pin"></i> จังหวัด กรุงเทพมหานคร </div>
                                    </>
                                    :
                                    null
                            }
                            {
                                this.props.show_stock == true ?
                                    <>
                                        <div className="ant-card-province text-gray-5 font-th mb-0 mt-0 py-0 "><i className="fe-package"></i> จำนวนคงเหลือ : {this.props.shopitem.first_stock} {this.props.shopitem.first_priceunit}</div>
                                    </>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    {
                        this.props.shopitem.first_pricenew > 0 ?
                            <div className="ribbon-wrapper-red">
                                <div className="ribbon-red font-th">ลดราคา</div>
                            </div>
                            : null
                    }
                </div>
            </div>
        );
    }
};

CardProductDemo.propTypes = {
    callback_editproduct: PropTypes.func,
};


export default CardProductDemo;
