import React, { useState, useEffect } from 'react';

import axios from 'axios';
import './Login.css';
import ModalPopupMessage from '../components/modal/ModalPopupMessage';

function Login(props) {

    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const saveToken = userToken => {
        sessionStorage.setItem('meya-management', JSON.stringify(userToken));
    };

    useEffect(() => {
        saveToken({
            username: '',
            role: '',
            token: '',
            logintime: '',
            uid: '',
        })
    }, []);

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function callback_signin(data) {
        props.callback_signin(data);
    }

    function handleSubmit() {
        var formelement = document.getElementById('accesspanel')
        if (formelement.checkValidity() === false) {
            formelement.classList.add("was-validated");
        }
        else {
            const formData = new FormData(formelement);
            axios.post(props.app_hostname + "/api/account/auth/office_signin", formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                },
            })
                .then((response) => {
                    if (response.data.status === 'OK') {
                        saveToken(response.data.userinfo)
                        callback_signin(response.data.userinfo)
                    } else if (response.data.status === 'login-fail') {
                        saveToken({
                            username: '',
                            role: '',
                            token: '',
                            logintime: '',
                            uid: '',
                        })
                        setModal_popup({ modaltype: 'modal-security', headers: 'Unauthorized', message: response.data.message, enable: true });
                    }
                    else {
                        setModal_popup({ modaltype: 'modal-error', headers: 'Error', message: response.data.message, enable: true });
                    }
                })
                .catch((err) => {
                    setModal_popup({ modaltype: 'modal-error', headers: 'Error', message: err.message, enable: true });
                });
        }
    }

    return (
        <div id="mainroot" className="root front-container">
            <section id="content" className="content">
                <div className="content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="content__wrap">

                        <div className="card shadow-lg card-login">
                            <div className="card-body">
                                <div className="text-center">
                                    <img src="/images/Meya-logo1.png" alt="Logo" className="logo mb-2" />
                                    <h1 className="h5 mb-1 mt-3 login-header">
                                        Meya Management Platform</h1>
                                    <p className="h5 mb-2 mt-2 login-subheader">Signin with your account</p>
                                </div>
                                <form className="mt-4" id="accesspanel" action="" method="POST" encType="multipart/form-data">
                                    <div className="mb-3">
                                        <input type="text" className="form-control" placeholder="Username" autoFocus
                                            id="username" name="username" required />
                                    </div>
                                    <div className="mb-3">
                                        <input type="password" className="form-control" placeholder="Password" id="password"
                                            name="password" required />
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="remember"
                                            name="remember" value="remember-me" />
                                        <label htmlFor="_dm-loginCheck" className="form-check-label">
                                            Remember me
                                        </label>
                                    </div>
                                    <div className="d-grid mt-5">
                                        <button className="btn btn-info btn-lg" type="button" onClick={() => { handleSubmit() }}>Sign In</button>
                                    </div>
                                </form>
                                <p className="mt-3 mb-0">Version 1.0.3</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                options={modal_popup}
            />
        </div>
    )
}


export default Login