import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

import './Navbar.css';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';

function Navbar(props) {
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });
    const [modal_userinfo, setModal_userinfo] = useState({
        class: '',
    });

    const [change_password, setChange_password] = useState(false);
    const [change_password_style, setChange_password_style] = useState({ display: "none", zIndex: "10002 !important" });
    const [change_backdrop_style, setChange_backdrop_style] = useState({ display: "none", zIndex: "10001 !important" });

    const ref = useRef(null);

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!ref.current?.contains(event.target)) {
                if (modal_userinfo.class !== '') {
                    setModal_userinfo({ class: '' })
                    setChange_password(false)
                }
            }
        };

        window.addEventListener("mousedown", handleOutSideClick);

        return () => {
            window.removeEventListener("mousedown", handleOutSideClick);
        };
    }, [ref, modal_userinfo.class]);

    useEffect(() => {
        if (change_password) {
            setChange_password_style({ display: "block", zIndex: "10002 !important" })
            setChange_backdrop_style({ display: "block", zIndex: "10001 !important" })

            document.getElementById('password').value = ""
            document.getElementById('confirm-password').value = ""
        } else {
            setChange_password_style({ display: "none", zIndex: "10002 !important" })
            setChange_backdrop_style({ display: "none", zIndex: "10001 !important" })
        }
    }, [change_password]);

    function submit_password() {
        var pass1 = document.getElementById('password')
        var pass2 = document.getElementById('confirm-password')
        if (pass1.value !== pass2.value) {
            pass2.setCustomValidity("Passwords Don't Match");
        } else {
            pass2.setCustomValidity("");
        }

        var formelement = document.getElementById('form-password')
        if (formelement.checkValidity() === false) {
            formelement.classList.add("was-validated");
        } else {
            formelement.classList.remove("was-validated");
            var formdata = new FormData()
            formdata.append('username', props.permitdata.username)
            formdata.append('password', pass2.value)

            axios.post(props.app_hostname + "/api/account/office/changepassword", formdata, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    if (response.data.status === 'OK') {
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'Success',
                            message: 'Your password has been change, system will automatic signout for your account',
                            enable: true
                        });
                    } else {
                        setModal_popup({ modaltype: 'modal-security', headers: 'Unauthorized', message: response.data.message, enable: true });
                    }
                })
                .catch((err) => {
                    setModal_popup({
                        modaltype: 'modal-error', headers: 'Error', message: err.message, enable: true
                    });
                });
        }
    }

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
        if (modal_popup.modaltype === "modal-success") {
            props.callback_signout('x')
        }
    }

    return (
        <header className="header">
            <div className="header__inner">
                <div className="header__brand">
                    <div className="brand-wrap">
                        <Link to="#" className="brand-img stretched-link">
                            <img src="/images/meya-logo1.png" alt="Nifty Logo" className="Nifty logo" width="500" height="400" />
                        </Link>
                        <div className="brand-title font-popins">MEYA</div>
                    </div>
                </div>

                <div className="header__content">
                    <div className="header__content-start">
                        <button type="button" className="nav-toggler header__btn btn btn-icon btn-sm" >
                            <i className="fe-menu"></i>
                        </button>

                        <div className="app-box">
                            <h6 className="header-appname">Meya Management Platform</h6>
                            <p className="header-appname-sub">Meya Management Tool for Office</p>
                        </div>
                    </div>

                    <div className="header__content-end">
                        {
                            props.slip_unread > 0 ?
                                <Link to="/order/payslip" className="msg-unread tag-warning" ><i className="far fa-newspaper"></i> Pay Slip
                                </Link>
                                :
                                null
                        }
                        {
                            props.messages_chat_unread > 0 ?
                                <Link to="/helpdesk/chat" className="msg-unread tag-warning" ><i className="fab fa-rocketchat"></i> Messages
                                </Link>
                                :
                                null
                        }
                        <div className="userslogin d-none d-md-block">{props.permitdata.username}</div>
                        <div className="dropdown usersbox">
                            <button className="btn btn-sm " type="button" onClick={() => { setModal_userinfo({ class: 'show' }) }} >
                                <i className="fas fa-user-alt"></i>
                            </button>

                            <div ref={ref} className={"dropdown-menu dropdown-menu-end w-md-350px " + (modal_userinfo.class)} style={{ position: "absolute", inset: "0px auto auto 0px", margin: "0px", transform: "translate(-307px, 34px)" }}>
                                <div className="d-flex align-items-center border-bottom px-1 py-2">
                                    <div className="flex-grow-1 ms-3">
                                        <h5 className="mb-0"><i className="fas fa-user-shield username"></i> Hi! {props.permitdata.username}</h5>
                                        <span className="text-dark">{props.permitdata.email}</span>
                                        <div className="">Login time : {moment(new Date(props.permitdata.logintime)).format('DD MMM YYYY HH:mm:ss')}</div>
                                    </div>
                                    <div className="font-poppins text-info">{props.permitdata.role}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="list-group list-group-borderless h-100 py-1">
                                            < Link className="list-group-item list-group-item-action" onClick={() => { setModal_userinfo({ class: '' }); setChange_password(!change_password) }}>
                                                <i className="fas fa-key fs-5 me-2" style={{ width: "15px" }}></i> Change Password
                                            </Link>
                                            <Link to="#" className="list-group-item list-group-item-action" onClick={() => { setModal_userinfo({ class: '' }); props.callback_signout('x') }}>
                                                <i className="fas fa-unlock-alt fs-5 me-2" style={{ width: "15px" }}></i> Logout
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div>
                <div className="modal fade show modal-password" data-bs-backdrop="static" aria-labelledby="demo-default-modal" aria-hidden="true" style={change_password_style} >
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-4">
                                <div className="text-center">
                                    <i className="fas fa-key mt-1"></i>
                                    <h4 className="mt-2 font-popins">Change Your Password</h4>
                                    <form id="form-password">
                                        <div className="row mb-3">
                                            <div className="col-md-12 mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="daymin">New Password</label>
                                                    <input type="password" className="form-control" id="password" name="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="daymin">Confirm new Password</label>
                                                    <input type="password" className="form-control" id="confirm-password" name="confirm-password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"d-md-flex justify-content-between"}>
                                            <button type="button" className="btn btn-warning btn-sm my-2" onClick={() => submit_password()}>Confirm Change</button>
                                            <button type="button" className="btn btn-light btn-sm my-2" onClick={() => { setChange_password(false) }}>Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"modal-backdrop fade show "} style={change_backdrop_style} ></div>
            </div>


            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                options={modal_popup}
            />
        </header >
    )
}

export default Navbar