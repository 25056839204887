import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Routes,
  Route,
  useLocation,
  useNavigate
} from "react-router-dom";

import moment from 'moment'
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/900.css";

import Navbar from './components/navbar/Navbar';
import Sidebar from './components/sidebar/Sidebar';

import LoginPage from './pages/Login';
import OrderPage from './pages/order/Order';
import HelpDeskPage from './pages/helpdesk/HelpDesk';
import ExtraPage from './pages/extra/Extra';
import RefferencePage from './pages/refference/Refference';
import AccountOfficePage from './pages/account-office/AccountOffice';
import AccountShopPage from './pages/account-shop/AccountShop';
import ProductPage from './pages/product/Product';
import APISettingPage from './pages/api-setting/APISetting';

import "./App.css";
import './components/notification/Notify.css';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const [systemdatetime, setSystemdatetime] = useState(moment(new Date()).format('DD MMM YYYY HH:mm:ss'));
  const [app_hostname, setApp_hostname] = useState('https://api.meya.co.th');
  //const [app_hostname, setApp_hostname] = useState('http://localhost:8080');

  const [isConnectionOpen_notify, setIsConnectionOpen_notify] = useState(false);
  const [messages_raw, setMessages_raw] = useState({});
  const [messages_chat_buffer, setMessages_chat_buffer] = useState({});
  const [messages_chat_unread, setMessages_chat_unread] = useState(0);
  const notify_ws = useRef();
  const [notify_data, setNotify_data] = useState([]);
  const [notify_count, setNotify_count] = useState(0);
  const [slip_unread, setSlip_unread] = useState(0);

  function generate_token(length) {
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];
    for (var i = 0; i < length; i++) {
      var j = (Math.random() * (a.length - 1)).toFixed(0);
      b[i] = a[j];
    }
    return b.join("");
  }


  const getTokenObject = () => {
    const tokenString = sessionStorage.getItem('meya-management');
    if (tokenString !== "undefined") {
      const userToken = JSON.parse(tokenString);
      return userToken
    }
    return {}
  };

  const getToken = () => {
    const tokenString = sessionStorage.getItem('meya-management');
    if (tokenString !== "undefined") {
      const userToken = JSON.parse(tokenString);
      return userToken?.token
    }
    return null
  };

  const [token, setToken] = useState(getToken());
  const [permitdata, setPermitdata] = useState(getTokenObject());

  const saveToken = userToken => {
    setToken(userToken.token);
    sessionStorage.setItem('meya-management', JSON.stringify(userToken));
  };



  useEffect(() => {
    var tokenx = getToken()
    if (!tokenx) {
      if (location.pathname !== "/" && location.pathname !== "") {
        navigate('/');
      }
    }
    if (location.pathname === "/helpdesk/chat") {
      setMessages_chat_unread(0)
    }
    if (location.pathname === "/order/payslip") {
      setSlip_unread(0)
    }
  }, [location, token]);

  useEffect(() => {
    let timer_1s = null
    timer_1s = window.setInterval(() => {
      setSystemdatetime(moment(new Date()).format('DD MMM YYYY HH:mm:ss'))
    }, 1000);
    return () => {
      window.clearInterval(timer_1s)
    };
  }, []);

  function callback_signin(message) {
    setPermitdata(message)
    saveToken(message)
    //navigate('/measurement/overview');
    setMessages_chat_unread(message.messages_chat_unread)
  }

  function callback_signout(message) {
    saveToken({
      username: '',
      role: '',
      token: '',
      logintime: '',
      uid: '',
    })
    setPermitdata({
      username: '',
      role: '',
      token: '',
      logintime: '',
      uid: '',
    })
    navigate('/');
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setNotify_count(notify_count + 1);
      if (notify_count > 1) {
        setNotify_count(1);
        if (permitdata !== null) {
          if (permitdata.token !== "" && permitdata.token !== "-" && isConnectionOpen_notify === false) {
            notify_ws.current = new WebSocket("wss://marketlive.meya.co.th/live/chat/connect?username=admin&&token=" + permitdata.token);

            notify_ws.current.onopen = () => {
              console.log("Connection opened");
              setIsConnectionOpen_notify(true);
            };

            notify_ws.current.onmessage = (event) => {
              const data = JSON.parse(event.data);
              setMessages_raw(data)
            };
            notify_ws.current.onclose = (event) => {
              console.log("Connection Lose");
              setIsConnectionOpen_notify(false);
            };

            return () => {
              console.log("Cleaning up...");
              notify_ws.current.close();
            };
          }
        }
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [notify_count]);

  useEffect(() => {
    if (permitdata !== null) {
      if (permitdata.token !== undefined) {
        if (permitdata.token !== "" && permitdata.token !== "-" && isConnectionOpen_notify === false) {
          notify_ws.current = new WebSocket("wss://marketlive.meya.co.th/live/chat/connect?username=admin&&token=" + permitdata.token);

          notify_ws.current.onopen = () => {
            console.log("Connection opened");
            setIsConnectionOpen_notify(true);
          };

          notify_ws.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setMessages_raw(data)
          };
          notify_ws.current.onclose = (event) => {
            console.log("Connection Lose");
            setIsConnectionOpen_notify(false);
          };

          return () => {
            console.log("Cleaning up...");
            notify_ws.current.close();
          };
        }
      }
    }
  }, [permitdata]);

  useEffect(() => {
    if (permitdata !== null) {
      if (messages_raw.from !== undefined && messages_raw.to !== undefined) {
        if (messages_raw.topic === "chat" && (messages_raw.from === "admin" || messages_raw.to === "admin")) {
          if (location.pathname === "/helpdesk/chat") {
            setMessages_chat_buffer(messages_raw);
          } else {
            setMessages_chat_buffer({});
            setMessages_chat_unread(messages_chat_unread + 1)
          }
        }
        if ((messages_raw.topic === "order" && messages_raw.to === "admin")) {
          if (messages_raw.popup_enable === true) {
            var data = {
              id: generate_token(30),
              icon: messages_raw.popup_class,
              title: messages_raw.popup_title,
              subtitle: messages_raw.popup_subtitle,
              actions: ["รับทราบ"]
            }
            setNotify_data(oldArray => [...oldArray, data]);
          }
          if (messages_raw.mode === "shoporder-slipuploaded") {
            setSlip_unread(slip_unread + 1)
          }
        }
      }
    }
  }, [messages_raw]);

  function callback_notify_ack(notify_id) {
    document.getElementById(notify_id).style.animation = "flyOutRight 0.3s ease-out forwards"
  }

  function callback_notify_animation(notify_id) {
    var cls = document.getElementById(notify_id)
    if (cls.className === 'my-3 notification__box flyInRight') {
      cls.className = 'my-3 notification__box'
    } else if (cls.className === 'my-3 notification__box') {
      setNotify_data(notify_data.filter(function (item) {
        return item.id !== notify_id
      }))
    }
  }

  return (
    <div>
      {
        token ?
          <div id="mainroot" className="root mn--min mn--sticky hd--sticky hd--fair">
            <Navbar
              callback_signout={message => { callback_signout(message) }}
              permitdata={permitdata}
              app_hostname={app_hostname}
              messages_chat_unread={messages_chat_unread}
              slip_unread={slip_unread}
            />
            <Sidebar
              permitdata={permitdata}
              app_hostname={app_hostname}
            />
            <Routes>
              <Route
                path="/order/*"
                element={<OrderPage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  data={{
                    systemtime: systemdatetime
                  }}
                />} />
              <Route
                path="/extra/*"
                element={<ExtraPage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  data={{
                    systemtime: systemdatetime
                  }}
                />} />
              <Route
                path="/reference/*"
                element={<RefferencePage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  data={{
                    systemtime: systemdatetime
                  }}
                />} />
              <Route
                path="/helpdesk/*"
                element={<HelpDeskPage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  messages_chat_buffer={messages_chat_buffer}
                  isConnectionOpen_notify={isConnectionOpen_notify}
                  notify_ws={notify_ws}
                  data={{
                    systemtime: systemdatetime
                  }}
                />} />
              <Route
                path="/accountshop/*"
                element={<AccountShopPage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  data={{
                    systemtime: systemdatetime
                  }}
                />} />
              <Route
                path="/accountoffice"
                element={<AccountOfficePage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  data={{
                    systemtime: systemdatetime
                  }}
                />} />
              <Route
                path="/product/*"
                element={<ProductPage
                  permitdata={permitdata}
                  app_hostname={app_hostname}
                  data={{
                    systemtime: systemdatetime
                  }}
                />} />
              <Route
                path="/apisetting/*"
                element={<APISettingPage
                  permitdata={permitdata}
                  app_hostname={app_hostname}
                  data={{
                    systemtime: systemdatetime
                  }}
                />} />
            </Routes>

            <div className={"notification " + (notify_data.length > 0 ? "" : "d-none")} >
              {
                notify_data.map((notify, index) => (
                  index < 10 ?
                    <div className="my-3 notification__box flyInRight" id={notify.id} key={notify.id} onAnimationEnd={() => callback_notify_animation(notify.id)}>
                      <div className="notification__content">
                        <div className={"notification__icon " + notify.icon}>
                          {
                            notify.icon === "error" ?
                              <i className='fe-alert-triangle'></i>
                              : notify.icon === "warning" ?
                                <i className='fe-bell'></i>
                                : notify.icon === "success" ?
                                  <i className='fe-check-circle'></i>
                                  :
                                  <i className='fe-help-circle'></i>
                          }
                        </div>
                        <div className="notification__text">
                          <div className="notification__text-title">{notify.title}</div>
                          <div className="notification__text-subtitle">{notify.subtitle}</div>
                        </div>
                      </div>
                      <div className="notification__btns">
                        <button className="notification__btn" type="button" data-dismiss="ZjYyO3zBVV7SUDG8cLBt5NyUllmyuT" onClick={() => { callback_notify_ack(notify.id) }}><span className="notification__btn-text">รับทราบ</span></button>
                      </div>
                    </div>
                    :
                    null
                ))
              }
            </div>

          </div>
          :
          <Routes>
            <Route exact path="" element={<LoginPage
              callback_signin={message => {
                callback_signin(message)
              }}
              app_hostname={app_hostname} />} />
            <Route exact path="/" element={<LoginPage
              callback_signin={message => { callback_signin(message) }}
              app_hostname={app_hostname}
            />} />
          </Routes>
      }
    </div>
  );
}

export default App;
