import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';
import CardProductDemo from '../../components/card-product/CardProductDemo';
import './ConsoleProductItems.css';

function ProductItemPreview(props) {
    const imgfile1_Ref = useRef(null);
    const imgfile2_Ref = useRef(null);
    const imgfile3_Ref = useRef(null);

    const inputfile1_Ref = useRef(null);
    const inputfile2_Ref = useRef(null);
    const inputfile3_Ref = useRef(null);

    const [PreviewImage1, setPreviewImage1] = useState("/images/blank.png");
    const [PreviewImage2, setPreviewImage2] = useState("/images/blank.png");
    const [PreviewImage3, setPreviewImage3] = useState("/images/blank.png");
    const [PreviewIndex, setPreviewIndex] = useState(1);

    const [productcategory_list, setProductCategory_List] = useState([]);
    const [productcategory_selected, setProductCategory_Selected] = useState('');
    const [filtertext, setFiltertext] = useState("");

    const [productitem_list, setProductItem_List] = useState([]);
    const [productitem_list_filtered, setProductItem_List_Filtered] = useState([]);
    const [productitem_selected, setProductItem_Selected] = useState({});
    const [productmode, setProductmode] = useState('');
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });
    const [modal_product, setModal_product] = useState(false);


    useEffect(() => {
        if (productcategory_selected === '') {
            if (productcategory_list.length > 0) {
                setProductCategory_Selected(productcategory_list[0].productcategory_id)
            }
        }
        if (filtertext !== "") {
            setProductItem_List_Filtered(productitem_list.filter((item, index) => (item.productitem_id.toLowerCase().includes(filtertext.toLowerCase()) || item.productitem_name.toLowerCase().includes(filtertext.toLowerCase()) || item.productitem_inter.toLowerCase().includes(filtertext.toLowerCase()))))
        } else {
            setProductItem_List_Filtered(productitem_list.filter((item, index) => item.productcategory_id === productcategory_selected))
        }
    }, [productcategory_list, productitem_list, productcategory_selected]);

    useEffect(() => {
        if (filtertext !== "") {
            setProductItem_List_Filtered(productitem_list.filter((item, index) => (item.productitem_id.toLowerCase().includes(filtertext.toLowerCase()) || item.productitem_name.toLowerCase().includes(filtertext.toLowerCase()) || item.productitem_inter.toLowerCase().includes(filtertext.toLowerCase()))))
        } else {
            setProductItem_List_Filtered(productitem_list.filter((item, index) => item.productcategory_id === productcategory_selected))
        }
    }, [filtertext]);

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        deleteProduct()
    }

    useEffect(() => {
        getProductList()

        document.getElementById('select-image1').value = [];
        document.getElementById('select-image2').value = [];
        document.getElementById('select-image3').value = [];
    }, []);

    async function getProductList() {
        setmodal_load({ enable: true, message: "Loading product item list." })
        await sleep(500)

        const formData = new FormData();
        formData.append('filter', 'approved')

        axios.post(props.app_hostname + "/api/product/item/getlist", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setProductItem_List(response.data.products_item)
                    setProductCategory_List(response.data.products_category)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    function manageProduct(mode, selectdata) {
        document.getElementById('select-image1').value = [];
        document.getElementById('select-image2').value = [];
        document.getElementById('select-image3').value = [];


        var formelement = document.getElementById('form-item')
        formelement.classList.remove("was-validated");

        setPreviewIndex(1)
        setPreviewImage1("/images/blank.png")
        setPreviewImage2("/images/blank.png")
        setPreviewImage3("/images/blank.png")

        setProductItem_Selected(selectdata)
        setModal_product(true)
        setProductmode(mode)
        if (mode === 'add') {
            document.getElementById('productcategory_name').value = selectdata.productcategory_id
            document.getElementById('productitem_id').value = "-"
            document.getElementById('productitem_name').value = ""
            document.getElementById('productitem_inter').value = ""
            document.getElementById('productitem_desc').value = ""
            document.getElementById('productitem_barcode').value = ""
            document.getElementById('productitem_width').value = ""
            document.getElementById('productitem_height').value = ""
            document.getElementById('productitem_deep').value = ""
            document.getElementById('productitem_weight').value = ""
        } else if (mode === 'edit') {
            document.getElementById('productcategory_name').value = selectdata.productcategory_id
            document.getElementById('productitem_id').value = selectdata.productitem_id
            document.getElementById('productitem_name').value = selectdata.productitem_name
            document.getElementById('productitem_inter').value = selectdata.productitem_inter
            document.getElementById('productitem_desc').value = selectdata.productitem_desc
            document.getElementById('productitem_barcode').value = selectdata.productitem_barcode
            document.getElementById('productitem_width').value = selectdata.productitem_width
            document.getElementById('productitem_height').value = selectdata.productitem_height
            document.getElementById('productitem_deep').value = selectdata.productitem_deep
            document.getElementById('productitem_weight').value = selectdata.productitem_weight

            setPreviewIndex(1)
            setPreviewImage1(props.app_hostname + "/api/product/file/productitem/" + selectdata.productitem_id + "/" + selectdata.productitem_id + "-1-" + selectdata.img1_key + ".jpg")
            setPreviewImage2(props.app_hostname + "/api/product/file/productitem/" + selectdata.productitem_id + "/" + selectdata.productitem_id + "-2-" + selectdata.img2_key + ".jpg")
            setPreviewImage3(props.app_hostname + "/api/product/file/productitem/" + selectdata.productitem_id + "/" + selectdata.productitem_id + "-3-" + selectdata.img3_key + ".jpg")
        } else {
            setPreviewIndex(1)
            setPreviewImage1("/images/blank.png")
            setPreviewImage2("/images/blank.png")
            setPreviewImage3("/images/blank.png")
        }
    }

    async function handleSubmit() {
        var formelement = document.getElementById('form-item')
        if (formelement.checkValidity() === false) {
            formelement.classList.add("was-validated");
        }
        else {
            var f1 = document.getElementById('select-image1').value
            var f2 = document.getElementById('select-image2').value
            var f3 = document.getElementById('select-image3').value
            var rx = false
            if ((f1.length > 0 && f2.length > 0 && f3.length > 0 && productmode === 'add') || productmode === 'edit') {
                rx = true
            } else {
                rx = false
            }

            if (rx === true) {
                setmodal_load({ enable: true, message: "Save product item to database." })
                await sleep(500)

                formelement.classList.remove("was-validated");
                const formData = new FormData(formelement);
                formData.append('username', props.permitdata.username)
                axios.post(props.app_hostname + "/api/product/item/" + productmode, formData, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data;',
                        "Authorization": "Bearer " + props.permitdata.token
                    },
                })
                    .then((response) => {
                        setmodal_load({ enable: false, message: "" })
                        setProductItem_Selected({})
                        document.getElementById('select-image1').value = [];
                        document.getElementById('select-image2').value = [];
                        document.getElementById('select-image3').value = [];

                        if (response.data.status === 'OK') {
                            setProductItem_List(response.data.products)
                            setModal_product(false)
                            setModal_popup({
                                modaltype: 'modal-success',
                                headers: 'Success',
                                message: "Your product item has been saved to database.",
                                enable: true
                            });
                        }
                        else {
                            setModal_product(false)
                            setModal_popup({
                                modaltype: 'modal-error',
                                headers: 'Error',
                                message: response.data.message,
                                enable: true
                            });
                        }
                    })
                    .catch((err) => {
                        setmodal_load({ enable: false, message: "" })
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: err.message,
                            enable: true
                        });
                    });
            } else {
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: "Please attach product image files",
                    enable: true
                });
            }

        }
    }

    async function deleteProduct() {
        setmodal_load({ enable: true, message: "Deleting product item from database." })
        await sleep(500)
        
        var postdata = {
            productitem_id: productitem_selected.productitem_id
        }
        axios.post(props.app_hostname + "/api/product/item/delete", postdata, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                setProductItem_Selected({})
                if (response.data.status === 'OK') {
                    setProductItem_List(response.data.products)
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'Success',
                        message: "Your product item has been deleted from database.",
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }


    const handleImageChange1 = (event) => {
        const image = event.target.files[0];
        setPreviewImage1(URL.createObjectURL(image))
    };
    const handleImageChange2 = (event) => {
        const image = event.target.files[0];
        setPreviewImage2(URL.createObjectURL(image))
    };
    const handleImageChange3 = (event) => {
        const image = event.target.files[0];
        setPreviewImage3(URL.createObjectURL(image))
    };

    function preview_back() {
        if (PreviewIndex > 1) {
            setPreviewIndex(PreviewIndex - 1)
        }
    }

    function preview_next() {
        if (PreviewIndex < 3) {
            setPreviewIndex(PreviewIndex + 1)
        }
    }

    function callback_editproduct(item) {
        manageProduct('edit', item)
    }

    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="far fa-file-image color-orange"></i> Product Item Preview</h4>
                            <p className="mb-3">Preview all product item with item card styles</p>
                            <hr className="new-section-xs mb-2" />
                            <div className="d-flex justify-content-start gap-3 mt-2">
                                <button className="btn btn-success btn-sm" onClick={() => { manageProduct('add', []) }}><i className="fas fa-plus"></i> Add New</button>
                                <button className="btn btn-success btn-sm" onClick={() => { getProductList() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-1 mb-md-2">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-6 col-xl-4 col-xxl-3">
                            <div className="d-block mb-3">
                                <label htmlFor="status">Category</label>
                                <select id="status" name="status" className="form-select" value={productcategory_selected} onChange={(e) => {
                                    setProductCategory_Selected(e.target.value); setFiltertext("")
                                }}>
                                    {
                                        productcategory_list.map((category, index) => (
                                            <option value={category.productcategory_id} key={category.productcategory_id}>{category.productcategory_name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
                            <div className="d-block mb-3">
                                <label htmlFor="status">Search Product Item</label>
                                <div className="form-group position-ralative">
                                    <input type="text" className={"form-control" + (filtertext.length > 0 ? " filtered" : "")} placeholder='Item ID, Item Name TH or EN' value={filtertext} onChange={(e) => { setFiltertext(e.target.value) }} />
                                    {
                                        filtertext.length > 0 ?
                                            <div className="form-control-append" onClick={() => { setFiltertext("") }}>
                                                <i className='fe-x'></i>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                        <h5>Product Item Preview List <span>({productitem_list_filtered.length} Items)</span></h5>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="flex-container py-0">
                                {
                                    productitem_list_filtered.map((item, index) => (
                                        <CardProductDemo key={index}
                                            shopitem={item}
                                            show_stock={false}
                                            show_shop={true}
                                            first_pricebase={99}
                                            callback_editproduct={message => { callback_editproduct(message) }}
                                        ></CardProductDemo>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="modal meya-overide " style={{ display: modal_product ? "block" : "none" }}>
                        <div className="modal-dialog modal-xl modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {
                                        productmode === 'add' ?
                                            <h4 className="modal-title"><i className="fas fa-capsules color-orange"></i> Add Product Item</h4>
                                            :
                                            <h4 className="modal-title"><i className="fas fa-capsules color-orange"></i> Edit Product Item</h4>
                                    }
                                    <i className="fe-x modal-close" onClick={() => {
                                        setModal_product(false)
                                    }}></i>
                                </div>
                                <div className="modal-body">
                                    <form id="form-item" action="" method="POST" encType="multipart/form-data">
                                        <div className="row">
                                            <div className="col-md-5 mb-4 mb-md-0">
                                                <div className="meya-slide-container">
                                                    <div className="meya-slick-carousel mb-2">
                                                        <div className={"js-slide " + (PreviewIndex === 1 ? "active" : null)}>
                                                            <img className="img-fluid" src={PreviewImage1} alt="Product view 1" />
                                                        </div>
                                                        <div className={"js-slide " + (PreviewIndex === 2 ? "active" : null)}>
                                                            <img className="img-fluid" src={PreviewImage2} alt="Product view 2" />
                                                        </div>
                                                        <div className={"js-slide " + (PreviewIndex === 3 ? "active" : null)}>
                                                            <img className="img-fluid" src={PreviewImage3} alt="Product view 3" />
                                                        </div>
                                                        <div className="icon-slide icon-slideback" onClick={() => { preview_back() }}><i className="fas fa-arrow-left"></i> </div>
                                                        <div className="icon-slide icon-slidenext" onClick={() => { preview_next() }}><i className="fas fa-arrow-right"></i> </div>
                                                    </div>
                                                    <div className="meya-slick-carousel-small">
                                                        <div className={"js-slide " + (PreviewIndex === 1 ? "active" : null)} style={{ cursor: "pointer" }}>
                                                            <img ref={imgfile1_Ref} className={"img-fluid " + (PreviewIndex === 1 ? "active" : null)} src={PreviewImage1} alt="Product view 1" />
                                                            <input ref={inputfile1_Ref} className="product-img-input" accept="image/*" type="file" id="select-image1" name="productimage1" onChange={handleImageChange1} />
                                                        </div>
                                                        <div className={"js-slide " + (PreviewIndex === 2 ? "active" : null)} style={{ cursor: "pointer" }}>
                                                            <img ref={imgfile2_Ref} className={"img-fluid " + (PreviewIndex === 2 ? "active" : null)} src={PreviewImage2} alt="Product view 2" />
                                                            <input ref={inputfile2_Ref} className="product-img-input" accept="image/*" type="file" id="select-image2" name="productimage2" onChange={handleImageChange2} />
                                                        </div>
                                                        <div className={"js-slide " + (PreviewIndex === 3 ? "active" : null)} style={{ cursor: "pointer" }}>
                                                            <img ref={imgfile3_Ref} className={"img-fluid " + (PreviewIndex === 3 ? "active" : null)} src={PreviewImage3} alt="Product view 3" />
                                                            <input ref={inputfile3_Ref} className="product-img-input" accept="image/*" type="file" id="select-image3" name="productimage3" onChange={handleImageChange3} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-7 mb-md-6 mb-lg-0">
                                                <div className="row mb-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="productitem_id">Product Item ID</label>
                                                            <input type="text" className="form-control" id="productitem_id" name="productitem_id" defaultValue={productitem_selected.productitem_id} readOnly required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="" htmlFor="productcategory_name">Product Category
                                                            </label>
                                                            <select className="form-select" id="productcategory_name" name="productcategory_name" defaultValue={productitem_selected.productcategory_name} required>
                                                                {
                                                                    productcategory_list.map((product) => (
                                                                        <option value={product.productcategory_id} key={product.productcategory_id}>{product.productcategory_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="productitem_barcode">Barcode</label>
                                                            <input type="text" className="form-control" id="productitem_barcode" name="productitem_barcode" defaultValue={productitem_selected.productitem_barcode} minLength="5" autoComplete='off' required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="productitem_name">Product Item Name TH</label>
                                                            <input type="text" className="form-control" id="productitem_name" name="productitem_name" defaultValue={productitem_selected.productitem_name} minLength="5" autoComplete='off' required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="productitem_inter">Product Item Name EN</label>
                                                            <input type="text" className="form-control" id="productitem_inter" name="productitem_inter" defaultValue={productitem_selected.productitem_inter} minLength="5" autoComplete='off' required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label htmlFor="productitem_desc">Product Item Description</label>
                                                            <textarea className="form-control" name="productitem_desc" id="productitem_desc" rows="4" cols="50" defaultValue={productitem_selected.productitem_desc} minLength="5" autoComplete='off' required />
                                                        </div>
                                                    </div>
                                                </div>

                                                <p className="font-th mb-0">Product Size</p>
                                                <div className="row">
                                                    <div className="col-md-3 mb-1">
                                                        <div className="form-group">
                                                            <label htmlFor="productitem_width">Width (cm.)
                                                            </label>
                                                            <input type="number" className="form-control" name="productitem_width" id="productitem_width" min="0.01" step="0.01" defaultValue={productitem_selected.productitem_width} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 mb-1">
                                                        <div className="form-group">
                                                            <label htmlFor="productitem_height">Long (cm.)
                                                            </label>
                                                            <input type="number" className="form-control" name="productitem_height" id="productitem_height" min="0.01" step="0.01" defaultValue={productitem_selected.productitem_height} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 mb-1">
                                                        <div className="form-group">
                                                            <label htmlFor="productitem_deep">Height/Deep (cm.)
                                                            </label>
                                                            <input type="number" className="form-control" name="productitem_deep" id="productitem_deep" min="0.01" step="0.01" defaultValue={productitem_selected.productitem_deep} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 mb-1">
                                                        <div className="form-group">
                                                            <label htmlFor="productitem_weight">Weight (g.)
                                                            </label>
                                                            <input type="number" className="form-control" name="productitem_weight" id="productitem_weight" min="0.01" step="0.01" defaultValue={productitem_selected.productitem_weight} required />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-light  btn-sm" type="button" onClick={() => {
                                        setProductItem_Selected({})
                                        setModal_product(false)
                                    }}><i className="fe-x"></i> Cancel</button>
                                    <button className="btn btn-warning btn-sm" onClick={() => { handleSubmit() }}><i className="fe-save"></i> Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modal_product ?
                    <div className="meya-backdrop"></div>
                    :
                    null
            }
        </div>
    )
}

export default ProductItemPreview