import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';

function APISettingEmail(props) {
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {

    }

    async function api_Send_find_trackingcode() {
        document.getElementById('tracking_code').value = ""
        setmodal_load({ enable: true, message: "Finding tracking code from order ID." })
        await sleep(500)

        const formData = new FormData()
        formData.append('order_id', document.getElementById('order_id').value)
        formData.append('ship_by', document.getElementById('ship_by').value)

        axios.post("https://notify.meya.co.th/webhook/notify/delivery/findtracking", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === "OK") {
                    document.getElementById('tracking_code').value = response.data.tracking_code
                }
                else {
                    document.getElementById('tracking_code').value = ""
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: "Hook notify server error",
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    async function api_Send_delivery_hook() {
        setmodal_load({ enable: true, message: "Save email api data to database." })
        await sleep(500)

        const formData = new FormData()
        formData.append('tracking_code', document.getElementById('tracking_code').value)
        formData.append('order_status', document.getElementById('order_status').value)

        axios.post("https://notify.meya.co.th/webhook/notify/delivery/shippop", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer BAhAFE5C5xw22BGDylfIgi4L0zy51z2C7VcUchiTtLJx5kzi7yD1EhmoS28BEp7e"
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.success === 1) {
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'Success',
                        message: "Hook notify server recieved your data.",
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: "Hook notify server error",
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="fas fa-truck color-orange"></i> Shippop Delivery</h4>
                            <p className="mb-3">Test delivery web hook notify.</p>
                            <hr className="new-section-xs mb-2" />
                        </div>
                    </div>

                    <h5>Tracking Code Finder</h5>
                    <form id="form-tracking" action="" method="POST" encType="multipart/form-data">
                        <div className="row mt-2 mb-0 mb-md-0">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="tracking_code">Order ID</label>
                                    <input type="text" className="form-control" id="order_id" name="order_id" placeholder='Type your order here' autoComplete='off' required />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="order_status">Ship by</label>
                                    <select className="form-select" id="ship_by" name="ship_by" required>
                                        <option value="shipping_tracking_code" >Seller</option>
                                        <option value="return_tracking_code" >Buyer</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="d-flex justify-content-start gap-3 mt-2 mb-5">
                        <button className="btn btn-info btn-sm mt-0" onClick={() => { api_Send_find_trackingcode() }}>Get Shippop Tracking Code</button>
                    </div>

                    <h5>Hook Trigger</h5>
                    <form id="form-delivery" action="" method="POST" encType="multipart/form-data">
                        <div className="row mt-2 mb-0 mb-md-0">
                            <div className="col-md-12 col-xl-6">
                                <div className="form-group">
                                    <label htmlFor="tracking_code">Shippop Tracking Code</label>
                                    <input type="text" className="form-control" id="tracking_code" name="tracking_code" autoComplete='off' required />
                                </div>
                                <div className="form-group mt-1">
                                    <label htmlFor="order_status">Delivery Status</label>
                                    <select className="form-select" id="order_status" name="order_status" required>
                                        <option value="booking" >booking</option>
                                        <option value="cancel" >cancel</option>
                                        <option value="shipping" >shipping</option>
                                        <option value="package_detail" >package_detail</option>
                                        <option value="problem" >problem</option>
                                        <option value="complete" >complete</option>
                                        <option value="return" >return</option>
                                        <option value="pending_transfer" >pending_transfer</option>
                                        <option value="transferred" >transferred</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="d-flex justify-content-start gap-3 mt-2">
                        <button className="btn btn-warning btn-sm mt-0" onClick={() => { api_Send_delivery_hook() }}>Send Hook Data</button>
                    </div>

                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
        </div>
    )
}

export default APISettingEmail