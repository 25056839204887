import React, { useState, useEffect } from 'react';
import {
    Link,
} from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import ModalLoad from '../../components/modal/ModalLoad';
import ModalPopupMessage from '../../components/modal/ModalPopupMessage';

function RefferenceParcel(props) {
    const [parcel_list, setParcel_list] = useState([]);
    const [parcel_selected, setParcel_Selected] = useState('');
    const [parcelmode, setParcelmode] = useState('');
    const [modal_load, setmodal_load] = useState({ enable: false, message: "" })
    const [modal_popup, setModal_popup] = useState({
        modaltype: '',
        headers: '',
        message: '',
        enable: false
    });
    const [modal_parcel, setModal_parcel] = useState(false);

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    function showDialogCallback_General(message) {
        setModal_popup({
            modaltype: '',
            headers: '',
            message: '',
            enable: false
        });
    }

    function showDialogCallback_Confirm(message) {
        deleteParcel()
    }

    useEffect(() => {
        getParcelList()
    }, []);

    async function getParcelList() {
        setmodal_load({ enable: true, message: "Loading parcel list." })
        await sleep(500)

        const formData = new FormData();
        formData.append('filter', 'approved')

        axios.post(props.app_hostname + "/api/order/parcel/getall", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setParcel_list(response.data.parcel)
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    function manageProduct(mode, parcel_data) {
        setParcel_Selected(parcel_data)
        setModal_parcel(true)
        setParcelmode(mode)
        if (mode === 'add') {
            document.getElementById('parcel_id').value = "-"
            document.getElementById('parcel_name').value = ""
            document.getElementById('parcel_code').value = ""
            document.getElementById('parcel_width').value = 0
            document.getElementById('parcel_length').value = 0
            document.getElementById('parcel_height').value = 0
        } else {
            document.getElementById('parcel_id').value = parcel_data.parcel_id
            document.getElementById('parcel_name').value = parcel_data.boxsize_name
            document.getElementById('parcel_code').value = parcel_data.parcel_code
            document.getElementById('parcel_width').value = parcel_data.width
            document.getElementById('parcel_length').value = parcel_data.length
            document.getElementById('parcel_height').value = parcel_data.height
        }
    }

    async function handleSubmit() {
        var formelement = document.getElementById('form-parcel')
        if (formelement.checkValidity() === false) {
            formelement.classList.add("was-validated");
        }
        else {
            setmodal_load({ enable: true, message: "Save parcel to database." })
            await sleep(500)

            formelement.classList.remove("was-validated");
            const formData = new FormData(formelement);
            formData.append('username', props.permitdata.username)

            axios.post(props.app_hostname + "/api/order/parcel/" + parcelmode, formData, {
                headers: {
                    Accept: 'application/json;',
                    'Content-Type': 'application/x-www-form-urlencoded;',
                    "Authorization": "Bearer " + props.permitdata.token
                },
            })
                .then((response) => {
                    setmodal_load({ enable: false, message: "" })
                    if (response.data.status === 'OK') {
                        setParcel_list(response.data.parcel)
                        setModal_parcel(false)
                        setModal_popup({
                            modaltype: 'modal-success',
                            headers: 'Success',
                            message: "Your parcel has been saved to database.",
                            enable: true
                        });
                    }
                    else {
                        setModal_parcel(false)
                        setModal_popup({
                            modaltype: 'modal-error',
                            headers: 'Error',
                            message: response.data.message,
                            enable: true
                        });
                    }
                })
                .catch((err) => {
                    setModal_parcel(false)
                    setmodal_load({ enable: false, message: "" })
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: err.message,
                        enable: true
                    });
                });
        }
    }

    async function deleteParcel() {
        setmodal_load({ enable: true, message: "Deleting pacel from database." })
        await sleep(500)

        const formData = new FormData();
        formData.append('parcel_id', parcel_selected.parcel_id)

        axios.post(props.app_hostname + "/api/order/parcel/delete", formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data;',
                "Authorization": "Bearer " + props.permitdata.token
            },
        })
            .then((response) => {
                setmodal_load({ enable: false, message: "" })
                if (response.data.status === 'OK') {
                    setParcel_list(response.data.parcel)
                    setModal_popup({
                        modaltype: 'modal-success',
                        headers: 'Success',
                        message: "Your parcel has been deleted from database.",
                        enable: true
                    });
                }
                else {
                    setModal_popup({
                        modaltype: 'modal-error',
                        headers: 'Error',
                        message: response.data.message,
                        enable: true
                    });
                }
            })
            .catch((err) => {
                setmodal_load({ enable: false, message: "" })
                setModal_popup({
                    modaltype: 'modal-error',
                    headers: 'Error',
                    message: err.message,
                    enable: true
                });
            });
    }

    return (
        <div id="page-content">
            <div className="panel">
                <div className="panel-body">
                    <div className="row mb-2">
                        <div className="col-xl-12">
                            <h4 className="mb-1"><i className="fas fa-box-open color-orange"></i> Parcel</h4>
                            <p className="mb-3">Manage all delivery parcel</p>
                            <hr className="new-section-xs mb-2" />
                            <div className="d-flex justify-content-start gap-3 mt-2">
                                <button className="btn btn-success btn-sm" onClick={() => { manageProduct('add', {}) }}><i className="fas fa-plus"></i> Add New</button>
                                <button className="btn btn-success btn-sm" onClick={() => { getParcelList() }}><i className="fas fa-sync-alt"></i> Refresh</button>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-1 mb-md-4">
                        <h5>Parcel List <span>({parcel_list.length} Parcels)</span></h5>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered" >
                                <thead>
                                    <tr className="admintable-header">
                                        <th className="id-fixwidth">#</th>
                                        <th>Parcel Name</th>
                                        <th>Parcel Code</th>
                                        <th>Width</th>
                                        <th>Length</th>
                                        <th>Height</th>
                                        <th>Edit By</th>
                                        <th className="text-center action-fixwidth">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        parcel_list.map((item, index) => (
                                            <tr key={item.boxsize_name}>
                                                <td><i className="fas fa-box-open rows-icon "></i> {index + 1}</td>
                                                <td className="font-bold my-0 py-0">{item.boxsize_name}</td>
                                                <td className="font-bold my-0 py-0">{item.parcel_code}</td>
                                                <td>{item.width}</td>
                                                <td>{item.length}</td>
                                                <td>{item.height}</td>
                                                <td>{item.useredit}<p className="my-0 py-0">{moment(new Date(item.edittime)).subtract(7, 'hours').format('DD MMM YYYY HH:mm:ss')}</p></td>
                                                <td>
                                                    <Link className="btn btn-icon btn-sm btn-success btn-hover btn-bordered" title="Edit" href="#" onClick={() => { manageProduct('edit', item) }}><i className="fas fa-pen"></i></Link>
                                                    <Link className="btn btn-icon btn-sm btn-danger btn-hover btn-bordered mx-1" title="Delete" href="#" onClick={() => {
                                                        setParcel_Selected(item);
                                                        setModal_popup({
                                                            modaltype: 'modal-confirm',
                                                            headers: 'Confirmation',
                                                            message: "Please click on confirm button to continue this operation.",
                                                            enable: true
                                                        });
                                                    }}><i className="fas fa-eraser"></i></Link>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="modal meya-overide" style={{ display: modal_parcel ? "block" : "none" }}>
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {
                                        parcelmode === 'add' ?
                                            <h4 className="modal-title"><i className="fas fa-capsules color-orange"></i> Add Parcel</h4>
                                            :
                                            <h4 className="modal-title"><i className="fas fa-capsules color-orange"></i> Edit Parcel</h4>
                                    }
                                    <i className="fe-x modal-close" onClick={() => {
                                        setModal_parcel(false)
                                    }}></i>
                                </div>
                                <div className="modal-body">
                                    <form id="form-parcel" action="" method="POST" encType="multipart/form-data">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="parcel_id">Parcel ID</label>
                                                    <input type="text" className="form-control" id="parcel_id" name="parcel_id" readOnly />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="parcel_name">Parcel Name</label>
                                                    <input type="text" className="form-control" id="parcel_name" name="parcel_name" minLength={5} autoComplete='off' required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="parcel_code">Parcel Code</label>
                                                    <input type="text" className="form-control" id="parcel_code" name="parcel_code" autoComplete='off' required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="parcel_width">Parcel Width</label>
                                                    <input type="number" className="form-control" id="parcel_width" name="parcel_width" min={1} autoComplete='off' required />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="parcel_length">Parcel Length</label>
                                                    <input type="number" className="form-control" id="parcel_length" name="parcel_length" min={1} autoComplete='off' required />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="parcel_height">Parcel Height</label>
                                                    <input type="number" className="form-control" id="parcel_height" name="parcel_height" min={1} autoComplete='off' required />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-light  btn-sm" type="button" onClick={() => {
                                        setModal_parcel(false)
                                    }}><i className="fe-x"></i> Cancel</button>
                                    <button className="btn btn-warning btn-sm" onClick={() => { handleSubmit() }}><i className="fe-save"></i> Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ModalLoad
                option={modal_load}
            ></ModalLoad>
            <ModalPopupMessage
                callback_general={message => { showDialogCallback_General(message) }}
                callback_confirm={message => { showDialogCallback_Confirm(message) }}
                options={modal_popup}
            />
            {
                modal_parcel ?
                    <div className="meya-backdrop"></div>
                    :
                    null
            }
        </div>
    )
}

export default RefferenceParcel